import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DeadfishAttributes } from 'generated/graphql';
import { RootState } from 'state/store';

export interface DraftDeadfish {
  totalDeadfishNumber: number;
  deadfishes?: DeadfishAttributes[];
}

export interface DraftDeadfishState {
  [draftId: string]: DraftDeadfish | undefined;
}

const initialState: DraftDeadfishState = {};

// slice
const draftDeadfishSlice = createSlice({
  name: 'draftDeadfish',
  initialState,
  reducers: {
    setDraft: (
      state,
      { payload }: PayloadAction<{ draftId: string; value?: DraftDeadfish }>
    ) => {
      return { ...state, [payload.draftId]: payload.value };
    },
  },
});

// reducer
export default draftDeadfishSlice.reducer;

// actions
export const { setDraft } = draftDeadfishSlice.actions;

// selectors
export const draftDeadfishSelectorCreator = (draftId: string) => (
  state: RootState
): DraftDeadfish | undefined => state.draftDeadfish[draftId];
