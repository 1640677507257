import { CustomFrequency } from 'components/FrequencyPicker';
import { TaskRecurrence, TaskRecurrencePeriod } from 'generated/graphql';

export function parseTaskRecurrences(
  recurrences: Pick<TaskRecurrence, 'frequency' | 'weekday'>[]
): {
  frequency: TaskRecurrencePeriod | 'CUSTOM';
  customFrequency?: CustomFrequency;
} {
  const isCustom = recurrences[0].frequency.value > 1 || recurrences.length > 1;
  const frequency = isCustom ? 'CUSTOM' : recurrences[0].frequency.period;

  let customFrequency: undefined | CustomFrequency;

  if (frequency === 'CUSTOM') {
    customFrequency = {
      every: recurrences[0].frequency.value,
      repeat: recurrences[0].frequency.period,
    };

    if (recurrences[0].frequency.period === TaskRecurrencePeriod.Week) {
      const weekdays = recurrences.map((recurrency) => recurrency.weekday);
      customFrequency.weekdays = weekdays;
    }
  }

  return {
    frequency,
    customFrequency,
  };
}
