import { login } from './login';

export const silentLogin = async (): Promise<void> => {
  const params = new URLSearchParams(window.location.search);

  const accessToken = params.get('access_token') || localStorage.getItem('accessToken');

  const refreshToken = params.get('refresh_token') || localStorage.getItem('refreshToken');

  const tenantId = params.get('tenant_id') || localStorage.getItem('tenantId');

  if (accessToken && refreshToken && tenantId) {
    return await login(accessToken, refreshToken, tenantId);
  }
};
