import { TaskPriority } from 'generated/graphql';

export enum DateFilterTypes {
  Single = 'SINGLE',
  Range = 'RANGE',
}
export type DateRange = { start: Date; end: Date };
export interface DateRangeFilter {
  type: DateFilterTypes.Range;
  value: DateRange;
}

export interface SingleDateFilter {
  type: DateFilterTypes.Single;
  value: Date;
}

export type DateFilter = SingleDateFilter | DateRangeFilter;

export type DateFilterState =
  | {
      type: DateFilterTypes.Single;
      value: number;
    }
  | {
      type: DateFilterTypes.Range;
      value: { start: number; end: number };
    };

export interface TasksFiltersState {
  date: DateFilterState;
  localityId?: string;
  categories?: string[];
  unitsIds?: string[];
  priorities?: TaskPriority[];
}
