import { Locale } from 'date-fns';

import { localesMap } from 'config/dateFns';
import { fallbackLocale } from 'config/i18n';

const useDateFnsLocale = (): Locale => {
  // TODO get somehow current lang state and return correct localesMap

  return localesMap[fallbackLocale];
};

export default useDateFnsLocale;
