import React, { FunctionComponent } from 'react';

import { Box, Container, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useTitle from 'hooks/useTitle';

export const ErrorPage: FunctionComponent = () => {
  const { t } = useTranslation();
  useTitle(t('Whoops'));

  return (
    <Container>
      <Box clone my={4}>
        <Grid alignItems="center" direction="column" container>
          <Typography variant="h5">{t('errors.default')}</Typography>
        </Grid>
      </Box>
    </Container>
  );
};
