import { useEffect, useRef } from 'react';

export const useDidMountEffect: typeof useEffect = (func, deps) => {
  const didMount = useRef(false);

  const effectCallback = didMount.current
    ? func
    : () => {
        didMount.current = true;
      };
  useEffect(effectCallback, deps);
};
