import { useEffect } from 'react';

import { add, isBefore } from 'date-fns';

import { getClient } from 'config/apollo';
import {
  GetAllCategoriesDocument,
  GetAllLocalitiesDocument,
  GetMeDocument,
  GetMeQuery,
  GetLocalityDetailsDocument,
  GetDeadfishCategoriesDocument,
} from 'generated/graphql';

import { useOnlineStatus } from './useOnlineStatus';

const isPrecacheValid = (): boolean => {
  const precachedAt = localStorage.getItem('precached-at');
  if (!precachedAt) return false;

  const precachedDate = new Date(precachedAt);
  const precacheExpiredDate = add(new Date(), { hours: -6 });
  if (isBefore(precachedDate, precacheExpiredDate)) return false;

  return true;
};

let precachingPending = false;

export const usePrecacheAppData = ({ skip: shouldSkip } = { skip: false }): void => {
  const isOnline = useOnlineStatus();
  const skip = isPrecacheValid() || shouldSkip || !isOnline;

  useEffect(() => {
    if (skip || precachingPending) return;
    precachingPending = true;

    getClient()
      .then(client => {
        client.cache.modify({
          fields: (existingRefs, { DELETE }) => DELETE,
        });
        client.cache.gc();

        return Promise.all([
          client.query({ query: GetAllCategoriesDocument }),
          client.query({ query: GetAllLocalitiesDocument }),
          client.query({ query: GetDeadfishCategoriesDocument }),
          client
            .query<GetMeQuery>({ query: GetMeDocument })
            .then(resp => {
              const localityId = resp.data?.me?.currentLocality?.id;
              if (!localityId) return resp;

              return client.query({
                query: GetLocalityDetailsDocument,
                variables: { localityId },
              });
            }),
        ]);
      })
      .then(() => {
        localStorage.setItem('precached-at', new Date().toISOString());
      })
      .finally(() => {
        precachingPending = false;
      });
  }, [skip]);
};
