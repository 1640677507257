import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

export enum TabTypes {
  DONE,
  TO_DO,
  LOCALITY,
  MEMBERS,
}

interface ActiveTabState {
  number: TabTypes;
}

const initialState: ActiveTabState = { number: TabTypes.TO_DO };

// slice
const mobileSlice = createSlice({
  name: 'activeTab',
  initialState,
  reducers: {
    setActiveTab: (state, { payload }: PayloadAction<TabTypes>) => ({
      ...state,
      number: payload,
    }),
  },
});

// reducer
export default mobileSlice.reducer;

// actions
export const { setActiveTab } = mobileSlice.actions;

// selectors
export const activeTabSelector = (state: RootState): ActiveTabState['number'] =>
  state.activeTab.number;
