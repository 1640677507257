import React, { FunctionComponent } from 'react';

import { Box, Container, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export const NotFoundPage: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Box clone my={4}>
        <Grid alignItems="center" direction="column" container>
          <Typography variant="h5">404</Typography>
          <Typography variant="subtitle1">{t('errors.404')}</Typography>
        </Grid>
      </Box>
    </Container>
  );
};
