import { makeStyles } from '@material-ui/core';

export const useFonts = makeStyles(
  (theme) => {
    return {
      light: {
        fontWeight: theme.typography.fontWeightLight,
      },
      regular: {
        fontWeight: theme.typography.fontWeightRegular,
      },
      medium: {
        fontWeight: theme.typography.fontWeightMedium,
      },
      bold: {
        fontWeight: theme.typography.fontWeightBold,
      },
      size10: {
        fontSize: theme.typography.pxToRem(10),
      },
      size12: {
        fontSize: theme.typography.pxToRem(12),
      },
      size14: {
        fontSize: theme.typography.pxToRem(14),
      },
      size16: {
        fontSize: theme.typography.pxToRem(16),
      },
      size18: {
        fontSize: theme.typography.pxToRem(18),
      },
      size20: {
        fontSize: theme.typography.pxToRem(20),
      },
    };
  },
  { index: 2 }
);
