import {
  Locale,
  startOfWeek as nativeStartOfWeek,
  format as nativeFormat,
  endOfWeek as nativeEndOfWeek,
  isSameWeek as nativeIsSameWeek,
  eachWeekOfInterval as nativeEachWeekOfInterval,
} from 'date-fns';
import en from 'date-fns/locale/en-US';

import { AvailableLocales } from './i18n';

export const localesMap = {
  [AvailableLocales.EN]: {
    ...en,
    options: {
      ...en.options,
      weekStartsOn: 1,
    },
  } as Locale,
};

const getCurrentLocaleId = (): AvailableLocales => {
  // TODO get somehow current lang and return correct localeId

  return AvailableLocales.EN;
};

const getCurrentLocale = (): Locale => {
  return localesMap[getCurrentLocaleId()];
};

export const startOfWeek: typeof nativeStartOfWeek = (date, options) => {
  return nativeStartOfWeek(date, {
    locale: getCurrentLocale(),
    ...options,
  });
};

export const endOfWeek: typeof nativeEndOfWeek = (date, options) => {
  return nativeEndOfWeek(date, {
    locale: getCurrentLocale(),
    ...options,
  });
};

export const isSameWeek: typeof nativeIsSameWeek = (
  dateLeft,
  dateRight,
  options
) => {
  return nativeIsSameWeek(dateLeft, dateRight, {
    locale: getCurrentLocale(),
    ...options,
  });
};

export const eachWeekOfInterval: typeof nativeEachWeekOfInterval = (
  interval,
  options
) => {
  return nativeEachWeekOfInterval(interval, {
    locale: getCurrentLocale(),
    ...options,
  });
};

export const format: typeof nativeFormat = (date, format, options) => {
  return nativeFormat(date, format, {
    locale: getCurrentLocale(),
    ...options,
  });
};

export const defaultDateFormat = (date: number | Date): string => {
  return format(date, 'yyyy-MM-dd');
};

export const getUTCDate = (date: Date | number): Date => {
  const utcString = new Date(date).toLocaleString('en-US', { timeZone: 'UTC' });
  return new Date(utcString);
};
