import { setLocale } from 'yup';

import i18n from './i18n';

setLocale({
  mixed: {
    notType: () => i18n.t('errors.mixed.notType'),
    required: () => i18n.t('errors.mixed.required'),
    oneOf: ({ values }) => i18n.t('errors.mixed.oneOf', { values }),
  },
  string: {
    min: ({ min }) => i18n.t('errors.string.min', { min }),
    max: ({ max }) => i18n.t('errors.string.max', { max }),
    email: () => i18n.t('errors.string.email'),
  },
  number: {
    positive: () => i18n.t('errors.number.positive'),
    integer: () => i18n.t('errors.number.integer'),
    min: ({ min }) => i18n.t('errors.number.min', { min }),
    max: ({ max }) => i18n.t('errors.number.max', { max }),
  },
  array: {
    min: ({ min }) => i18n.t('errors.array.min', { min }),
    max: ({ max }) => i18n.t('errors.array.max', { max }),
  },
});
