import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'state/store';

export interface ChatState {
  chatAuthed: boolean;
  chatUserId: number | null;
}

const initialState: ChatState = {
  chatAuthed: false,
  chatUserId: null,
};

// slice
const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChatAuth: (state, action: PayloadAction<boolean>) => {
      return { ...state, chatAuthed: action.payload };
    },
    setChatUserId: (state, action: PayloadAction<number>) => {
      return { ...state, chatUserId: action.payload };
    },
  },
});

// reducer
export default chatSlice.reducer;

// actions
export const { setChatAuth, setChatUserId } = chatSlice.actions;

// selectors
export const chatAuthSelector = (state: RootState): boolean => state.chat.chatAuthed;
export const chatUserIdSelector = (state: RootState): number | null => state.chat.chatUserId;
