import React, { Suspense, useEffect } from 'react';

import { isBrowser } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { ErrorBoundary, NotFoundPage } from 'components/ErrorBoundary';
import LoadingIndicator from 'components/LoadingIndicator';
import PrivateRoute from 'components/PrivateRoute';
import { useGoogleAnalytics } from 'config/google/ga';
import { useWatchUsersSubscription } from 'generated/graphql';
import { useErrorHandling } from 'hooks/useErrorHandling';
import { useOnlineStatusToasts } from 'hooks/useOnlineStatusToasts';
import { usePrecacheAppData } from 'hooks/usePrecacheAppData';
import useSilentLogin from 'hooks/useSilentLogin';
import { useVersionLogger } from 'hooks/useVersionLogger';
import { authSelector } from 'state/currentUser';
import { getRefreshAtTimeout } from 'utils/functions/getRefreshAtTimeout';

import { DateFilterTypes, setDateFilter } from './state/tasksFilters';

const lazyWithPreload: typeof React.lazy = factory => {
  const preload = factory();
  return React.lazy(() => preload);
};

// Root screens components must have default exports
const Login = lazyWithPreload(() => import('screens/Login'));
const Tasks = lazyWithPreload(() => import('screens/Tasks'));
const TaskDetails = lazyWithPreload(() => import('screens/TaskDetails'));
const ManageChecklists = React.lazy(() => import('screens/ManageChecklists'));
const Localities = React.lazy(() => import('screens/Localities'));
const LocalityDetails = React.lazy(() => import('screens/LocalityDetails'));
const Members = React.lazy(() => import('screens/Members'));
const TaskForm = lazyWithPreload(() => import('screens/TaskForm'));
const LocalityForm = React.lazy(() => import('screens/LocalityForm'));
const QnappenLayout = lazyWithPreload(() => import('components/QnappenLayout'));
const Profile = lazyWithPreload(() => import('screens/ProfileView'));
const IssueForm = lazyWithPreload(() => import('screens/IssueForm'));
const Chat = lazyWithPreload(() => import('screens/Chat'));
const NewChat = lazyWithPreload(() => import('screens/Chat/components/NewChat'));
const NewGroupChat = lazyWithPreload(() => import('screens/Chat/components/NewGroupChat'));

const App: React.FunctionComponent = () => {
  useVersionLogger();
  const disaptch = useDispatch();
  const authed = useSelector(authSelector);
  const { loading } = useSilentLogin();
  useErrorHandling();
  useOnlineStatusToasts();
  usePrecacheAppData({ skip: !authed });

  useGoogleAnalytics();

  useWatchUsersSubscription({
    skip: !authed,
  });

  function setTodayDayAsActiveAtMidnight() {
    setTimeout(function () {
      disaptch(
        setDateFilter({
          type: DateFilterTypes.Single,
          value: new Date(),
        }),
      );

      // we reset today day at 00:15, because we need to wait for backend to finish crone job to update tasks at 00:00
    }, getRefreshAtTimeout(0, 15));
  }

  useEffect(() => {
    setTodayDayAsActiveAtMidnight();

    // set today date as selected day in week carousel on app load
    disaptch(
      setDateFilter({
        type: DateFilterTypes.Single,
        value: new Date(),
      }),
    );
  }, []);

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <ErrorBoundary>
      <Suspense fallback={<LoadingIndicator />}>
        <Switch>
          <PrivateRoute path="/login" authed={!authed} redirect="/">
            <Login />
          </PrivateRoute>
          <Route path="/" exact>
            <Redirect to="/tasks" />
          </Route>

          <QnappenLayout>
            <Suspense fallback={<LoadingIndicator />}>
              <PrivateRoute path="/tasks" exact authed={authed} redirect="/login">
                <Tasks />
              </PrivateRoute>
              <PrivateRoute path="/tasks/:taskEventId" authed={authed} redirect="/login" exact>
                <TaskDetails />
              </PrivateRoute>
              <PrivateRoute path="/tasks/form/:formType/:taskEventId?/:startAt?" authed={authed} redirect="/login">
                <TaskForm />
              </PrivateRoute>
              <PrivateRoute path="/checklists" authed={authed} redirect="/login">
                <ManageChecklists />
              </PrivateRoute>

              <PrivateRoute path="/localities" exact authed={authed} redirect="/login">
                {isBrowser ? <Localities /> : <NotFoundPage />}
              </PrivateRoute>
              <PrivateRoute path="/localities/:localityId" exact authed={authed} redirect="/login">
                {isBrowser ? <LocalityDetails /> : <NotFoundPage />}
              </PrivateRoute>
              <PrivateRoute path="/localities/form/:formType/:localityId?" authed={authed} redirect="/login">
                {isBrowser ? <LocalityForm /> : <NotFoundPage />}
              </PrivateRoute>
              <PrivateRoute path="/profile/:userId?" authed={authed} exact={true} redirect={'/login'}>
                <Profile />
              </PrivateRoute>
              <PrivateRoute path="/report-issue" authed={authed} redirect="/login">
                {isBrowser ? <NotFoundPage /> : <IssueForm />}
              </PrivateRoute>
              <PrivateRoute path="/members" authed={authed} redirect="/login">
                {isBrowser ? <Members /> : <NotFoundPage />}
              </PrivateRoute>
              <PrivateRoute path="/chats/:id?" authed={authed} redirect="/login">
                {isBrowser ? <Chat /> : <NotFoundPage />}
              </PrivateRoute>
              <PrivateRoute path="/chat" authed={authed} redirect="/login">
                {isBrowser ? <NewChat /> : <NotFoundPage />}
              </PrivateRoute>
              <PrivateRoute path="/group-chat" authed={authed} redirect="/login">
                {isBrowser ? <NewGroupChat /> : <NotFoundPage />}
              </PrivateRoute>
            </Suspense>
          </QnappenLayout>
        </Switch>
      </Suspense>
    </ErrorBoundary>
  );
};

export default App;
