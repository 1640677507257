import { useEffect } from 'react';

import * as Apollo from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { toast } from 'components/Toast';

export * from '@apollo/client';

const useErrorToaster = (error: Error | null | undefined) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (error) {
      if (error.message === 'Network Error') {
        if (!navigator.onLine) return;
        toast.error(t('errors.network'));
        return;
      }
      toast.error(error.message);
    }
  }, [error]);
};

export const useQuery: typeof Apollo.useQuery = (...args) => {
  const resp = Apollo.useQuery(...args);
  useErrorToaster(resp.error);

  useEffect(() => {
    if (resp && resp.data && !resp.loading) {
      resp.refetch();
    }
  }, []);

  return resp;
};

export const useLazyQuery: typeof Apollo.useLazyQuery = (...args) => {
  const resp = Apollo.useLazyQuery(...args);
  useErrorToaster(resp[1].error);
  return resp;
};

export const useMutation: typeof Apollo.useMutation = (...args) => {
  const resp = Apollo.useMutation(...args);
  const { t } = useTranslation();
  const error = resp[1].error;
  useErrorToaster(error);

  useEffect(() => {
    if (error) {
      if (error.message === 'Network Error' && !navigator.onLine) {
        toast.warning(t('common.youAreOffline'), { timeout: 2000 });
      }
    }
  }, [error]);

  return resp;
};

export const useSubscription: typeof Apollo.useSubscription = (...args) => {
  const resp = Apollo.useSubscription(...args);
  useErrorToaster(resp.error);
  return resp;
};
