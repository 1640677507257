import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache, split } from '@apollo/client';
import { getMainDefinition, relayStylePagination } from '@apollo/client/utilities';
import ActionCable from 'actioncable';
import { LocalForageWrapper, persistCache } from 'apollo3-cache-persist';
import { buildAxiosFetch } from 'axios-fetch';
import ActionCableLink from 'graphql-ruby-client/dist/subscriptions/ActionCableLink';
import localForage from 'localforage';

import { refreshAccessToken } from 'utils/functions/auth';
import httpClient from 'utils/httpClient';

const wsURI = process.env.REACT_APP_WS_URI as string;

class AuthedWebSocket extends WebSocket {
  constructor(url: string, protocols?: string | string[]) {
    const accessToken = localStorage.getItem('accessToken');
    const tenantId = localStorage.getItem('tenantId');
    if (accessToken) {
      url = url + `?jwt=${accessToken}&tenant_id=${tenantId}`;
    }
    super(url, protocols);

    this.addEventListener('message', function refreshTokenHandler(event) {
      const message = JSON.parse(event.data);
      if (message.type === 'disconnect' && message.reason === 'unauthorized') {
        refreshAccessToken();
      }
    });
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
ActionCable.WebSocket = AuthedWebSocket;

const wsLink = new ActionCableLink({
  cable: ActionCable.createConsumer(wsURI),
});

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_APOLLO_URI,
  fetch: buildAxiosFetch(httpClient),
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  (wsLink as unknown) as ApolloLink,
  httpLink,
);

export const cache = new InMemoryCache({
  typePolicies: {
    Task: {
      fields: {
        checklistTasks: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    Details: {
      fields: {
        doneUnits: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        deadfishes: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    Query: {
      fields: {
        taskEventComments: relayStylePagination(['taskEventId']),
        users: relayStylePagination(['name', 'roles', 'currentLocalityIds', 'localityIds']),
        localities: relayStylePagination(['name']),
        categories: relayStylePagination(),
        tasksEvents: relayStylePagination(['localityId', 'categoryId', 'onDate', 'fromDate', 'toDate']),
      },
    },
  },
});

const client = new ApolloClient({
  cache,
  link: splitLink,
});

let initialized = false;

const initializing = persistCache({
  cache,
  storage: new LocalForageWrapper(localForage),
}).then(() => {
  initialized = true;
});

export const getClient = async (): Promise<typeof client> => {
  if (!initialized) {
    await initializing;
  }
  return client;
};
