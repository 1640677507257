import React, {
  FunctionComponent,
  ReactElement,
  useEffect,
  useState,
} from 'react';

import { ApolloClient, ApolloProvider } from '@apollo/client';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { InstallAppMessage } from 'components/InstallAppMessage';
import LoadingIndicator from 'components/LoadingIndicator';
import { ToastProvider } from 'components/Toast';
import { getClient } from 'config/apollo';
import { theme } from 'config/materialUI';
import useDateFnsLocale from 'hooks/useDateFnsLocale';
import { store } from 'state/store';

const ProvidersProvider: FunctionComponent<{
  children: ReactElement;
}> = ({ children }) => {
  const locale = useDateFnsLocale();
  const [client, setClient] = useState<ApolloClient<unknown>>();

  useEffect(() => {
    getClient().then(setClient);
  }, []);

  if (!client) return <LoadingIndicator />;

  return (
    <React.StrictMode>
      <ApolloProvider client={client}>
        <ReduxProvider store={store}>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider locale={locale} utils={DateFnsUtils}>
              <CssBaseline />
              <BrowserRouter>{children}</BrowserRouter>
              <InstallAppMessage />
              <ToastProvider />
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </ReduxProvider>
      </ApolloProvider>
    </React.StrictMode>
  );
};

export default ProvidersProvider;
