export enum DrawerStateTypes {
  CLOSED,
  OPEN,
}

export interface DrawerState {
  state: DrawerStateTypes;
}

export const initialState: DrawerState = { state: DrawerStateTypes.CLOSED };
