import React, { ReactNode } from 'react';

import { Redirect, Route, RouteProps } from 'react-router-dom';

export interface PrivateRouteProps extends RouteProps {
  authed: boolean;
  redirect: string;
  children: ReactNode;
}

const PrivateRoute: React.FunctionComponent<PrivateRouteProps> = ({
  authed,
  redirect,
  children,
  ...routeProps
}) => {
  return (
    <Route
      {...routeProps}
      render={(props) =>
        authed ? (
          children
        ) : (
          <Redirect
            to={{ pathname: redirect, state: { from: props.location } }}
          />
        )
      }
    ></Route>
  );
};

export default PrivateRoute;
