import { GridPositionType, LocalityGrid } from 'generated/graphql';

export function getGridRowsAndCols(
  arrangement: LocalityGrid | GridPositionType
): { cols: number; rows: number } {
  const match = /^_(\d)X(\d)$/.exec(arrangement);
  if (!match) throw new Error("Can't parse rows and cols");
  const cols = parseInt(match[1]);
  const rows = parseInt(match[2]);
  return { cols, rows };
}
