import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isMobile } from 'react-device-detect';

import { RootState } from 'state/store';

export type TaskDetailsTabType =
  | 'details'
  | 'deadFishCounter'
  | 'comments'
  | 'units';

interface TaskDetailsState {
  activeTab: TaskDetailsTabType;
}

const initialState: TaskDetailsState = {
  activeTab: isMobile ? 'details' : 'comments',
};

// slice
const taskDetailsSlice = createSlice({
  name: 'taskDetails',
  initialState,
  reducers: {
    setActiveTab: (
      state,
      { payload }: PayloadAction<TaskDetailsTabType | undefined>
    ) => {
      if (!payload) return { ...state, activeTab: initialState.activeTab };
      return { ...state, activeTab: payload };
    },
  },
});

// reducer
export default taskDetailsSlice.reducer;

// actions
export const { setActiveTab } = taskDetailsSlice.actions;

// selectors
export const activeTabSelector = (
  state: RootState
): TaskDetailsState['activeTab'] => state.taskDetails.activeTab;
