/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';

import { toast } from 'components/Toast';
import i18n from 'config/i18n';

import { ErrorPage } from './ErrorPage';
interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error;
}

class PureErrorBoundary extends React.Component<
  RouteComponentProps,
  ErrorBoundaryState
> {
  constructor(props: RouteComponentProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.

    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: unknown) {
    // You can also log the error to an error reporting service
    // TODO: add some error logging
    // logErrorToMyService(error, errorInfo);

    switch (error.name) {
      case 'ChunkLoadError':
        toast.error(i18n.t('errors.network'));
        break;
      default:
        toast.error(error.message);
    }

    this.timeout = window.setTimeout(() => {
      this.setState({ hasError: false });
    }, 5000);
  }
  timeout?: number;

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    if (this.state.hasError) return <ErrorPage />;

    return this.props.children;
  }
}

export const ErrorBoundary = withRouter(PureErrorBoundary);
