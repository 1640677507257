import { useEffect } from 'react';

const useTitle = (title: string): void => {
  useEffect(() => {
    const originalTitle = document.title;
    document.title = `${originalTitle} - ${title}`;
    return () => {
      document.title = originalTitle;
    };
  }, [title]);
};

export default useTitle;
