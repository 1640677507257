import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'state/store';

export interface DraftCommentsState {
  [draftId: string]: string | undefined;
}

const initialState: DraftCommentsState = {};

// slice
const draftCommentsSlice = createSlice({
  name: 'draftComments',
  initialState,
  reducers: {
    setDraft: (
      state,
      { payload }: PayloadAction<{ draftId: string; value?: string }>
    ) => {
      return { ...state, [payload.draftId]: payload.value };
    },
  },
});

// reducer
export default draftCommentsSlice.reducer;

// actions
export const { setDraft } = draftCommentsSlice.actions;

// selectors
export const draftCommentSelectorCreator = (draftId: string) => (
  state: RootState
): string | undefined => state.draftComments[draftId];
