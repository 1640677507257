import { getClient } from 'config/apollo';
import { fetchToken } from 'config/firebase';
import { CreateUserDeviceTokenDocument } from 'generated/graphql';
import { setAuth } from 'state/currentUser';
import { store } from 'state/store';

export const login = async (accessToken: string, refreshToken: string, tenantId: string): Promise<void> => {
  localStorage.setItem('refreshToken', refreshToken);
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('tenantId', tenantId);

  store.dispatch(setAuth(true));

  // subscribe to firebase notifications
  const deviceToken = localStorage.getItem('deviceToken');
  if (deviceToken) {
    return;
  }

  const client = await getClient();

  if (Notification.permission === 'granted') {
    fetchToken().then(token => {
      client.mutate({
        mutation: CreateUserDeviceTokenDocument,
        variables: { token: token },
      });
      localStorage.setItem('deviceToken', token);
    });
  }
};
