import { useEffect } from 'react';

import { silentLogin } from 'utils/functions/auth';

import { useAsyncFunction } from './useAsyncFunction';

const useSilentLogin = (): { loading: boolean; error?: Error } => {
  const [fn, state] = useAsyncFunction(silentLogin);

  useEffect(() => {
    fn();
  }, []);

  return state;
};

export default useSilentLogin;
