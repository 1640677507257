import { CSSProperties } from '@material-ui/core/styles/withStyles';

import { LocalityGrid } from 'generated/graphql';

import { getGridRowsAndCols } from './getGridRowsAndCols';

export function getGridTemplate(arrangement: LocalityGrid): CSSProperties {
  const { rows, cols } = getGridRowsAndCols(arrangement);

  let gridTemplateAreas = '';
  for (let row = 1; row <= rows; row++) {
    gridTemplateAreas += '"';
    for (let col = 1; col <= cols; col++) {
      gridTemplateAreas += `_${col}X${row} `;
    }
    gridTemplateAreas += '" ';
  }

  const gridTemplateColumns = `repeat(${cols},1fr)`;
  const gridTemplateRows = `repeat(${rows},1fr)`;

  return { gridTemplateAreas, gridTemplateColumns, gridTemplateRows };
}
