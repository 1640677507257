import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

export enum DoneTasksStateTypes {
  HIDE,
  SHOW,
}

interface TasksBoardState {
  number: DoneTasksStateTypes;
}

const initialState: TasksBoardState = { number: DoneTasksStateTypes.SHOW };

// slice
const doneTasksSlice = createSlice({
  name: 'doneTasks',
  initialState,
  reducers: {
    setDoneTasksState: (state, { payload }: PayloadAction<DoneTasksStateTypes>) => ({
      ...state,
      number: payload,
    }),
  },
});

// reducer
export default doneTasksSlice.reducer;

// actions
export const { setDoneTasksState } = doneTasksSlice.actions;

// selectors
export const doneTasksStateSelector = (state: RootState): TasksBoardState['number'] => state.doneTasks.number;
