import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Role } from 'generated/graphql';
import { RootState } from 'state/store';

interface MembersFiltersState {
  localityId?: string;
  search?: string;
  role?: Role;
}

const initialState: MembersFiltersState = {};

// slice
const membersFiltersSlice = createSlice({
  name: 'membersFilters',
  initialState,
  reducers: {
    setSearchFilter: (
      state,
      { payload }: PayloadAction<string | undefined>
    ) => {
      return { ...state, search: payload };
    },
    setRoleFilter: (state, { payload }: PayloadAction<Role | undefined>) => {
      return { ...state, role: payload };
    },
    setLocalityFilter: (
      state,
      { payload }: PayloadAction<string | undefined>
    ) => {
      return { ...state, localityId: payload };
    },
  },
});

// reducer
export default membersFiltersSlice.reducer;

// actions
export const {
  setSearchFilter,
  setRoleFilter,
  setLocalityFilter,
} = membersFiltersSlice.actions;

// selectors
export const searchFilterSelector = (
  state: RootState
): MembersFiltersState['search'] => state.membersFilters.search;

export const roleFilterSelector = (
  state: RootState
): MembersFiltersState['role'] => state.membersFilters.role;

export const localityFilterSelector = (
  state: RootState
): MembersFiltersState['localityId'] => state.membersFilters.localityId;
