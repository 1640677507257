import React, { FunctionComponent, useEffect, useState } from 'react';

import { Box, Grid, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { PlusSquare, X } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { isIOSSafari, isInstalled } from 'utils/functions';

const useStyles = makeStyles((theme) => {
  return {
    popper: {
      pointerEvents: 'all',
    },
    tooltip: {
      margin: theme.spacing(1, 0),
    },
  };
});

export const InstallAppMessage: FunctionComponent = () => {
  const [showMessage, setShowMessage] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    if (isIOSSafari && !isInstalled) {
      setShowMessage(true);
    }
  }, []);

  const handleTooltipClose = () => {
    setShowMessage(false);
  };

  const renderTitle = () => {
    return (
      <Grid
        container
        alignItems="center"
        direction="row"
        wrap="nowrap"
        spacing={1}
      >
        <Grid item>
          <PlusSquare />
        </Grid>
        <Grid item>
          <span>{t('ios.installApp')}</span>
        </Grid>
        <Grid item>
          <IconButton color="primary" size="small" onClick={handleTooltipClose}>
            <X />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <Tooltip
      title={renderTitle()}
      PopperProps={{
        disablePortal: true,
      }}
      classes={classes}
      onClose={handleTooltipClose}
      open={showMessage}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      arrow
    >
      <Box position="fixed" bottom={0} left="50%" />
    </Tooltip>
  );
};
