import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { toast } from 'components/Toast';

import { useDidMountEffect } from './useDidMountEffect';
import { useOnlineStatus } from './useOnlineStatus';

const timeout = 5000;

export const useOnlineStatusToasts = (): void => {
  const isOnline = useOnlineStatus();
  const { t } = useTranslation();

  useDidMountEffect(() => {
    if (isOnline) toast.info(t('common.youAreOnline'), { timeout });
  }, [isOnline]);

  useEffect(() => {
    if (!isOnline) toast.warning(t('common.youAreOffline'), { timeout });
  }, [isOnline]);
};
