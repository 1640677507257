import React, { FunctionComponent, SyntheticEvent } from 'react';

import {
  Slide,
  Snackbar,
  SnackbarCloseReason,
  SnackbarProps,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { Toast as ToastInterface } from 'state/toasts';

interface ToastProps extends Omit<ToastInterface, 'id'> {
  onClose: () => void;
}

export const Toast: FunctionComponent<ToastProps & SnackbarProps> = ({
  type,
  message,
  options,
  onClose,
  ...snackBarProps
}) => {
  const handleSnackBarClose = (
    e: SyntheticEvent,
    reason: SnackbarCloseReason
  ) => {
    if (reason === 'timeout') {
      onClose();
    }
  };

  return (
    <Snackbar
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      TransitionComponent={Slide}
      autoHideDuration={options?.timeout}
      onClose={handleSnackBarClose}
      {...snackBarProps}
    >
      <Alert onClose={onClose} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  );
};
