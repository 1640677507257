import { ThunkAction, Action, configureStore } from '@reduxjs/toolkit';

import chat from './chat';
import currentUser from './currentUser';
import doneTasks from './doneTasks';
import draftComments from './draftComments';
import draftDeadfish from './draftDeadfish';
import filtersDrawer from './filtersDrawer';
import localitiesFilters from './localitiesFilters';
import membersFilters from './membersFilters';
import activeTab from './mobileActiveTab';
import sideDrawer from './sideDrawer';
import taskDetails from './taskDetails';
import tasksBoard from './tasksBoard';
import tasksFilters from './tasksFilters';
import toasts from './toasts';

export const store = configureStore({
  reducer: {
    currentUser,
    chat,
    draftComments,
    draftDeadfish,
    localitiesFilters,
    membersFilters,
    taskDetails,
    tasksFilters,
    activeTab,
    sideDrawer,
    filtersDrawer,
    tasksBoard,
    doneTasks,
    toasts,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
