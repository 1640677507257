import { useState } from 'react';

import { useMountedRef } from './useMountedRef';

export function useAsyncFunction<Args extends Array<unknown>, Result>(
  func: (...args: Args) => Promise<Result>
): [
  (...funcArgs: Args) => Promise<Result>,
  { error?: Error; loading: boolean }
] {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const isMounted = useMountedRef();

  const asyncFunction = async (...args: Args): Promise<Result> => {
    setLoading(true);
    try {
      const result = await func(...args);
      return result;
    } catch (error) {
      if (isMounted.current) setError(error);
      throw error;
    } finally {
      if (isMounted.current) setLoading(false);
    }
  };

  return [asyncFunction, { loading, error }];
}
