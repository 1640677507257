import { getClient } from 'config/apollo';
import { DeleteUserDeviceTokenDocument, SignOutDocument } from 'generated/graphql';

const clientId = process.env.REACT_APP_AZURE_CLIENT_ID as string;
const redirectUri = process.env.REACT_APP_AZURE_LOGOUT_REDIRECT_URI as string;

export const logout = async (): Promise<void> => {
  const refresh_token = localStorage.getItem('refreshToken');
  const client = await getClient();

  if (refresh_token) {
    const deviceToken = localStorage.getItem('deviceToken');
    if (deviceToken) {
      await client.mutate({
        mutation: DeleteUserDeviceTokenDocument,
        variables: { token: deviceToken },
      });
      localStorage.removeItem('deviceToken');
    }

    await client.mutate({
      mutation: SignOutDocument,
      variables: { refresh_token },
    });
  }
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  await client.clearStore();
  localStorage.removeItem('precached-at');
};

export const MSlogout = async (): Promise<void> => {
  location.href = `https://login.microsoftonline.com/organizations/oauth2/v2.0/logout?post_logout_redirect_uri=${encodeURIComponent(
    redirectUri,
  )}&client_id=${encodeURIComponent(clientId)}`;
};
