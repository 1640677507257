import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DrawerState, DrawerStateTypes, initialState } from '../common/drawer';
import { RootState } from '../store';

// slice
const drawerSlice = createSlice({
  name: 'filtersDrawer',
  initialState,
  reducers: {
    setFiltersDrawerState: (
      state,
      { payload }: PayloadAction<DrawerStateTypes>
    ) => ({
      ...state,
      state: payload,
    }),
  },
});

// reducer
export default drawerSlice.reducer;

// actions
export const { setFiltersDrawerState } = drawerSlice.actions;

// selectors
export const filtersDrawerStateSelector = (
  state: RootState
): DrawerState['state'] => state.filtersDrawer.state;
