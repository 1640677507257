import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../store';

export enum BoardTypes {
  BOARD,
  CALENDAR,
}

interface TasksBoardState {
  number: BoardTypes;
}

const initialState: TasksBoardState = { number: BoardTypes.BOARD };

// slice
const boardSlice = createSlice({
  name: 'tasksBoard',
  initialState,
  reducers: {
    setBoardState: (state, { payload }: PayloadAction<BoardTypes>) => ({
      ...state,
      number: payload,
    }),
  },
});

// reducer
export default boardSlice.reducer;

// actions
export const { setBoardState } = boardSlice.actions;

// selectors
export const activeBoardTypeSelector = (state: RootState): TasksBoardState['number'] => state.tasksBoard.number;
