import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'state/store';

export interface CurrentUserState {
  authed: boolean;
}

const initialState: CurrentUserState = {
  authed: false,
};

// slice
const currentUserSlice = createSlice({
  name: 'currentUser',
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<boolean>) => {
      return { ...state, authed: action.payload };
    },
  },
});

// reducer
export default currentUserSlice.reducer;

// actions
export const { setAuth } = currentUserSlice.actions;

// selectors
export const authSelector = (state: RootState): boolean =>
  state.currentUser.authed;
