import React from 'react';

import ReactDOM from 'react-dom';

import 'config/i18n';
import 'config/wdyr';
import 'config/yup';
import 'config/google';

import App from './App';
import ProvidersProvider from './ProvidersProvider';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
  <ProvidersProvider>
    <App />
  </ProvidersProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: async (registration) => {
    // We want to run this code only if we detect a new service worker is
    // waiting to be activated.
    // Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
    if (registration && registration.waiting) {
      await registration.unregister();
      // Once the service worker is unregistered, we can reload the page to let
      // the browser download a fresh copy of our app (invalidating the cache)
      // TODO
      // jakiś modal z update'em
      // store.dispatch(serviceWorkerUpdate());
      window.location.reload();
    }
  },
});
