export function getRefreshAtTimeout(hours: number, minutes: number, seconds = 0): number {
  const now = new Date();
  const then = new Date();

  if (
    now.getHours() > hours ||
    (now.getHours() == hours && now.getMinutes() > minutes) ||
    (now.getHours() == hours && now.getMinutes() == minutes && now.getSeconds() >= seconds)
  ) {
    then.setDate(now.getDate() + 1);
  }
  then.setHours(hours);
  then.setMinutes(minutes);
  then.setSeconds(seconds);

  return then.getTime() - now.getTime();
}
