import React, { FunctionComponent } from 'react';

import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';

import { store } from 'state/store';
import {
  addToast,
  removeToast,
  Toast,
  ToastOptions,
  toastsSelector,
  ToastType,
  updateToast,
} from 'state/toasts';

import { Toast as ToastComponent } from './Toast';

const toastCreator = (type: ToastType) => {
  return (message: string, options?: ToastOptions) => {
    store.dispatch(
      addToast({
        message,
        type,
        options,
      })
    );
  };
};

export const toast = {
  success: toastCreator(ToastType.Success),
  error: toastCreator(ToastType.Error),
  info: toastCreator(ToastType.Info),
  warning: toastCreator(ToastType.Warning),
};

export const ToastProvider: FunctionComponent = () => {
  const toasts = useSelector(toastsSelector);
  const dispatch = useDispatch();

  const handleToastClose = (toast: Toast) => () => {
    dispatch(updateToast({ id: toast.id, open: false }));
  };

  const handleExit = (toast: Toast) => () => {
    dispatch(removeToast(toast.id));
  };

  return (
    <>
      {toasts.map((toast, index) => {
        return (
          <ToastComponent
            key={toast.id}
            {...toast}
            style={{
              transform: `translate(${isMobile ? '0%' : '-50%'},calc(${
                index * 100
              }% + ${index * 8}px))`,
              transition: 'transform 0.2s ease',
            }}
            onClose={handleToastClose(toast)}
            onExited={handleExit(toast)}
          />
        );
      })}
    </>
  );
};
