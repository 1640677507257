import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHooks from 'hooks/wrappedApolloHooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** E164PhoneNumberType, for example +47 458 42 231 */
  E164PhoneNumber: string;
  /** An ISO 8601-encoded date */
  ISO8601Date: string;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: string;
  /** Represents untyped JSON */
  JSON: any;
  /** URI (RFC2396): http/https schemas are allowed.  */
  Uri: any;
};

/** LocalityArrangement */
export type Arrangement = {
  __typename?: 'Arrangement';
  burs: Array<Bur>;
  feederIndex: Scalars['Int'];
  grid: LocalityGrid;
  id: Scalars['ID'];
};

export type AuthorizationResult = {
  __typename?: 'AuthorizationResult';
  /** Human-readable error message */
  message?: Maybe<Scalars['String']>;
  /** Reasons of check failure */
  reasons?: Maybe<FailureReasons>;
  /** Result of applying a policy rule */
  value: Scalars['Boolean'];
};

export type AuthorizedArguments = {
  __typename?: 'AuthorizedArguments';
  value: Array<Scalars['String']>;
};

/** Bur */
export type Bur = {
  __typename?: 'Bur';
  hasMerd: Scalars['Boolean'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  onGridIndex: Scalars['Int'];
  position: GridPositionType;
};

export type BursAttributes = {
  /** Used in update action to properly assign new attributes */
  id?: Maybe<Scalars['ID']>;
  position: GridPositionType;
  onGridIndex: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  hasMerd: Scalars['Boolean'];
};

/** TaskCategory */
export type Category = {
  __typename?: 'Category';
  canDestroy: AuthorizationResult;
  canEdit: AuthorizationResult;
  checklistTasks: Array<ChecklistTask>;
  default: Scalars['Boolean'];
  id: Scalars['ID'];
  kind: CategoryKind;
  name: Scalars['String'];
};

/** The connection type for Category. */
export type CategoryConnection = {
  __typename?: 'CategoryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CategoryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Category>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CategoryEdge = {
  __typename?: 'CategoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Category>;
};

export enum CategoryKind {
  Basic = 'BASIC',
  Deadfish = 'DEADFISH',
  Service = 'SERVICE',
}

/** ChecklistTask */
export type ChecklistTask = {
  __typename?: 'ChecklistTask';
  id: Scalars['ID'];
  order: Scalars['Int'];
  status: Scalars['String'];
  value: Scalars['String'];
};

export type ChecklistTaskAttributes = {
  value: Scalars['String'];
};

/** Autogenerated return type of ChecklistTaskStatusUpdated */
export type ChecklistTaskStatusUpdatedPayload = {
  __typename?: 'ChecklistTaskStatusUpdatedPayload';
  checklistTask: ChecklistTask;
};

export enum ChecklistTaskUpdateStatus {
  Done = 'DONE',
  Todo = 'TODO',
}

/** ChecklistTemplate */
export type ChecklistTemplate = {
  __typename?: 'ChecklistTemplate';
  canDestroy: AuthorizationResult;
  checklistTemplateTasks: Array<ChecklistTemplateTask>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** The connection type for ChecklistTemplate. */
export type ChecklistTemplateConnection = {
  __typename?: 'ChecklistTemplateConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ChecklistTemplateEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ChecklistTemplate>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ChecklistTemplateEdge = {
  __typename?: 'ChecklistTemplateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ChecklistTemplate>;
};

/** ChecklistTemplateTask */
export type ChecklistTemplateTask = {
  __typename?: 'ChecklistTemplateTask';
  id: Scalars['ID'];
  value: Scalars['String'];
};

export type ChecklistTemplateTaskAttributes = {
  value: Scalars['String'];
};

/** Comment */
export type Comment = {
  __typename?: 'Comment';
  attachments?: Maybe<Array<ImageAttachment>>;
  author: User;
  canDestroy: AuthorizationResult;
  canEdit: AuthorizationResult;
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  starred: Scalars['Boolean'];
  taskEvent: TaskEvent;
  updatedAt: Scalars['ISO8601DateTime'];
  value: Scalars['String'];
};

export type CommentAttributes = {
  value: Scalars['String'];
  /** Key from S3 direct upload, argument will be ignored in updateComment mutation */
  attachments?: Maybe<Array<DirectUploadAttributes>>;
};

/** The connection type for Comment. */
export type CommentConnection = {
  __typename?: 'CommentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CommentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Comment>>>;
  notSeenCount: Scalars['Int'];
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

/** An edge in a connection. */
export type CommentEdge = {
  __typename?: 'CommentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Comment>;
};

/** Autogenerated input type of CreateChecklistTemplate */
export type CreateChecklistTemplateInput = {
  name: Scalars['String'];
  checklistTemplateTasks: Array<ChecklistTemplateTaskAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateChecklistTemplate */
export type CreateChecklistTemplatePayload = {
  __typename?: 'CreateChecklistTemplatePayload';
  checklistTemplate?: Maybe<ChecklistTemplate>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateComment */
export type CreateCommentInput = {
  taskEventId: Scalars['ID'];
  attributes: CommentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateComment */
export type CreateCommentPayload = {
  __typename?: 'CreateCommentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  comment?: Maybe<Comment>;
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateDeviceToken */
export type CreateDeviceTokenInput = {
  token: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateDeviceToken */
export type CreateDeviceTokenPayload = {
  __typename?: 'CreateDeviceTokenPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
  user?: Maybe<User>;
};

/** Autogenerated input type of CreateLocality */
export type CreateLocalityInput = {
  name: Scalars['String'];
  managerId?: Maybe<Scalars['ID']>;
  aquatechnicianIds: Array<Scalars['ID']>;
  grid: LocalityGrid;
  feederIndex: Scalars['Int'];
  burs?: Maybe<Array<BursAttributes>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateLocality */
export type CreateLocalityPayload = {
  __typename?: 'CreateLocalityPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<ValidationError>;
  locality?: Maybe<Locality>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateTaskCategory */
export type CreateTaskCategoryInput = {
  name: Scalars['String'];
  kind: CategoryKind;
  checklistTasks?: Maybe<Array<ChecklistTaskAttributes>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateTaskCategory */
export type CreateTaskCategoryPayload = {
  __typename?: 'CreateTaskCategoryPayload';
  category?: Maybe<Category>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateTask */
export type CreateTaskInput = {
  attributes: TaskAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateTask */
export type CreateTaskPayload = {
  __typename?: 'CreateTaskPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
  task?: Maybe<Task>;
};

/** TaskDeadfish */
export type Deadfish = {
  __typename?: 'Deadfish';
  category: DeadfishCategory;
  id: Scalars['ID'];
  unit: Bur;
  value: Scalars['Int'];
};

export type DeadfishAttributes = {
  value: Scalars['Int'];
  deadfishCategoryId: Scalars['ID'];
};

/** DeadfishCategory */
export type DeadfishCategory = {
  __typename?: 'DeadfishCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** The connection type for DeadfishCategory. */
export type DeadfishCategoryConnection = {
  __typename?: 'DeadfishCategoryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<DeadfishCategoryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<DeadfishCategory>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type DeadfishCategoryEdge = {
  __typename?: 'DeadfishCategoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<DeadfishCategory>;
};

/** UnitDeadfishNumber */
export type DeadfishNumber = {
  __typename?: 'DeadfishNumber';
  id: Scalars['ID'];
  totalDeadfishNumber: Scalars['Int'];
  unit: Bur;
};

/** Autogenerated input type of DeleteChecklistTemplate */
export type DeleteChecklistTemplateInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteChecklistTemplate */
export type DeleteChecklistTemplatePayload = {
  __typename?: 'DeleteChecklistTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of DeleteDeviceToken */
export type DeleteDeviceTokenInput = {
  token: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteDeviceToken */
export type DeleteDeviceTokenPayload = {
  __typename?: 'DeleteDeviceTokenPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
  user?: Maybe<User>;
};

/** Autogenerated input type of DeleteLocality */
export type DeleteLocalityInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteLocality */
export type DeleteLocalityPayload = {
  __typename?: 'DeleteLocalityPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of DeleteTaskCategory */
export type DeleteTaskCategoryInput = {
  id: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteTaskCategory */
export type DeleteTaskCategoryPayload = {
  __typename?: 'DeleteTaskCategoryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of DeleteTask */
export type DeleteTaskInput = {
  taskEventId: Scalars['ID'];
  scope: TaskDeletionScope;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of DeleteTask */
export type DeleteTaskPayload = {
  __typename?: 'DeleteTaskPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** TaskDetails */
export type Details = {
  __typename?: 'Details';
  comments?: Maybe<CommentConnection>;
  deadfishes: Array<Deadfish>;
  doneUnits: Array<Bur>;
  id: Scalars['ID'];
  status: TaskStatus;
  unitsDeadfishNumbers: Array<DeadfishNumber>;
};

/** TaskDetails */
export type DetailsCommentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DirectUploadAttributes = {
  id: Scalars['String'];
  storage: Scalars['String'];
  metadata?: Maybe<Scalars['JSON']>;
};

/** Autogenerated input type of ExportTaskCsv */
export type ExportTaskCsvInput = {
  taskEventId: Scalars['ID'];
  email: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ExportTaskCsv */
export type ExportTaskCsvPayload = {
  __typename?: 'ExportTaskCsvPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

export type FailureReasons = {
  __typename?: 'FailureReasons';
  /** JSON-encoded map of reasons */
  details: Scalars['String'];
  /** Human-readable errors */
  fullMessages: Array<Scalars['String']>;
};

export enum GridPositionType {
  '1X1' = '_1X1',
  '1X2' = '_1X2',
  '1X3' = '_1X3',
  '1X4' = '_1X4',
  '1X5' = '_1X5',
  '1X6' = '_1X6',
  '1X7' = '_1X7',
  '1X8' = '_1X8',
  '2X1' = '_2X1',
  '2X2' = '_2X2',
  '2X3' = '_2X3',
  '2X4' = '_2X4',
  '2X5' = '_2X5',
  '2X6' = '_2X6',
  '2X7' = '_2X7',
  '2X8' = '_2X8',
  '3X1' = '_3X1',
  '3X2' = '_3X2',
  '3X3' = '_3X3',
  '3X4' = '_3X4',
  '3X5' = '_3X5',
  '3X6' = '_3X6',
  '3X7' = '_3X7',
  '3X8' = '_3X8',
}

export type ImageAttachment = {
  __typename?: 'ImageAttachment';
  fileName: Scalars['String'];
  mobileUrl: Scalars['Uri'];
  originalUrl: Scalars['Uri'];
  thumbnailUrl: Scalars['Uri'];
};

export type ImageAttachmentMobileUrlArgs = {
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};

export type ImageAttachmentThumbnailUrlArgs = {
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};

/** Locality */
export type Locality = {
  __typename?: 'Locality';
  aquatechnicians: UserConnection;
  arrangement: Arrangement;
  canDestroy: AuthorizationResult;
  canEdit: AuthorizationResult;
  id: Scalars['ID'];
  manager?: Maybe<User>;
  name: Scalars['String'];
};

/** Locality */
export type LocalityAquatechniciansArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Locality. */
export type LocalityConnection = {
  __typename?: 'LocalityConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LocalityEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Locality>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type LocalityEdge = {
  __typename?: 'LocalityEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Locality>;
};

export enum LocalityGrid {
  '2X6' = '_2X6',
  '2X7' = '_2X7',
  '2X8' = '_2X8',
  '3X5' = '_3X5',
  '3X6' = '_3X6',
  '3X8' = '_3X8',
}

/** Autogenerated input type of MarkComment */
export type MarkCommentInput = {
  /** Comment id */
  id: Scalars['ID'];
  starred: Scalars['Boolean'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of MarkComment */
export type MarkCommentPayload = {
  __typename?: 'MarkCommentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  comment?: Maybe<Comment>;
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of MarkTaskEventUnits */
export type MarkTaskEventUnitsInput = {
  taskEventId: Scalars['ID'];
  /** Done units ids */
  doneIds?: Maybe<Array<Scalars['ID']>>;
  /** Not done units ids */
  notDoneIds?: Maybe<Array<Scalars['ID']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of MarkTaskEventUnits */
export type MarkTaskEventUnitsPayload = {
  __typename?: 'MarkTaskEventUnitsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
  taskEvent?: Maybe<TaskEvent>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createChecklistTemplate?: Maybe<CreateChecklistTemplatePayload>;
  createComment?: Maybe<CreateCommentPayload>;
  createLocality?: Maybe<CreateLocalityPayload>;
  createTask?: Maybe<CreateTaskPayload>;
  createTaskCategory?: Maybe<CreateTaskCategoryPayload>;
  createUserDeviceToken?: Maybe<CreateDeviceTokenPayload>;
  deleteChecklistTemplate?: Maybe<DeleteChecklistTemplatePayload>;
  deleteLocality?: Maybe<DeleteLocalityPayload>;
  deleteTask?: Maybe<DeleteTaskPayload>;
  deleteTaskCategory?: Maybe<DeleteTaskCategoryPayload>;
  deleteUserDeviceToken?: Maybe<DeleteDeviceTokenPayload>;
  exportTaskCsv?: Maybe<ExportTaskCsvPayload>;
  /** Mark comment as starred/unstarred to show on every task occurrence */
  markComment?: Maybe<MarkCommentPayload>;
  markTaskEventUnits?: Maybe<MarkTaskEventUnitsPayload>;
  /** Set timestamp to save information about read comments */
  readTaskEventComments?: Maybe<ReadTaskEventCommentsPayload>;
  refreshToken?: Maybe<RefreshTokenPayload>;
  sendChatNotifications?: Maybe<SendChatNotificationPayload>;
  sendFeedback?: Maybe<SendFeedbackPayload>;
  setChecklistTaskUpdateStatus?: Maybe<SetChecklistTaskUpdateStatusPayload>;
  setTaskEventStatus?: Maybe<SetTaskEventStatusPayload>;
  setUserNotifications?: Maybe<SetUserNotificationsPayload>;
  signOut?: Maybe<SignOutPayload>;
  updateComment?: Maybe<UpdateCommentPayload>;
  updateLocality?: Maybe<UpdateLocalityPayload>;
  updateMyStatus?: Maybe<UpdateMyStatusPayload>;
  updateTask?: Maybe<UpdateTaskPayload>;
  updateTaskCategory?: Maybe<UpdateTaskCategoryPayload>;
  updateTaskEventDeadfishes?: Maybe<UpdateTaskEventDeadfishesPayload>;
  updateUser?: Maybe<UpdateUserPayload>;
};

export type MutationCreateChecklistTemplateArgs = {
  input: CreateChecklistTemplateInput;
};

export type MutationCreateCommentArgs = {
  input: CreateCommentInput;
};

export type MutationCreateLocalityArgs = {
  input: CreateLocalityInput;
};

export type MutationCreateTaskArgs = {
  input: CreateTaskInput;
};

export type MutationCreateTaskCategoryArgs = {
  input: CreateTaskCategoryInput;
};

export type MutationCreateUserDeviceTokenArgs = {
  input: CreateDeviceTokenInput;
};

export type MutationDeleteChecklistTemplateArgs = {
  input: DeleteChecklistTemplateInput;
};

export type MutationDeleteLocalityArgs = {
  input: DeleteLocalityInput;
};

export type MutationDeleteTaskArgs = {
  input: DeleteTaskInput;
};

export type MutationDeleteTaskCategoryArgs = {
  input: DeleteTaskCategoryInput;
};

export type MutationDeleteUserDeviceTokenArgs = {
  input: DeleteDeviceTokenInput;
};

export type MutationExportTaskCsvArgs = {
  input: ExportTaskCsvInput;
};

export type MutationMarkCommentArgs = {
  input: MarkCommentInput;
};

export type MutationMarkTaskEventUnitsArgs = {
  input: MarkTaskEventUnitsInput;
};

export type MutationReadTaskEventCommentsArgs = {
  input: ReadTaskEventCommentsInput;
};

export type MutationRefreshTokenArgs = {
  input: RefreshTokenInput;
};

export type MutationSendChatNotificationsArgs = {
  input: SendChatNotificationInput;
};

export type MutationSendFeedbackArgs = {
  input: SendFeedbackInput;
};

export type MutationSetChecklistTaskUpdateStatusArgs = {
  input: SetChecklistTaskUpdateStatusInput;
};

export type MutationSetTaskEventStatusArgs = {
  input: SetTaskEventStatusInput;
};

export type MutationSetUserNotificationsArgs = {
  input: SetUserNotificationsInput;
};

export type MutationSignOutArgs = {
  input: SignOutInput;
};

export type MutationUpdateCommentArgs = {
  input: UpdateCommentInput;
};

export type MutationUpdateLocalityArgs = {
  input: UpdateLocalityInput;
};

export type MutationUpdateMyStatusArgs = {
  input: UpdateMyStatusInput;
};

export type MutationUpdateTaskArgs = {
  input: UpdateTaskInput;
};

export type MutationUpdateTaskCategoryArgs = {
  input: UpdateTaskCategoryInput;
};

export type MutationUpdateTaskEventDeadfishesArgs = {
  input: UpdateTaskEventDeadfishesInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export enum PresignObject {
  Comment = 'COMMENT',
  Task = 'TASK',
}

export type PresignedUpload = {
  __typename?: 'PresignedUpload';
  fields: Scalars['JSON'];
  url: Scalars['Uri'];
};

export type Query = {
  __typename?: 'Query';
  categories?: Maybe<CategoryConnection>;
  category?: Maybe<Category>;
  checklistTemplate?: Maybe<ChecklistTemplate>;
  checklistTemplates?: Maybe<ChecklistTemplateConnection>;
  deadfishCategories?: Maybe<DeadfishCategoryConnection>;
  localities?: Maybe<LocalityConnection>;
  locality?: Maybe<Locality>;
  me?: Maybe<User>;
  presignUpload?: Maybe<PresignedUpload>;
  quickbloxSessionToken?: Maybe<QuickbloxSessionToken>;
  task?: Maybe<Task>;
  taskEvent?: Maybe<TaskEvent>;
  taskEventComments?: Maybe<CommentConnection>;
  tasksEvents?: Maybe<TaskEventConnection>;
  user?: Maybe<User>;
  users?: Maybe<UserConnection>;
};

export type QueryCategoriesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryCategoryArgs = {
  id: Scalars['ID'];
};

export type QueryChecklistTemplateArgs = {
  id: Scalars['ID'];
};

export type QueryChecklistTemplatesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryDeadfishCategoriesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type QueryLocalitiesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type QueryLocalityArgs = {
  id: Scalars['ID'];
};

export type QueryPresignUploadArgs = {
  fileName: Scalars['String'];
  fileType: Scalars['String'];
  objectType: PresignObject;
};

export type QueryTaskArgs = {
  id: Scalars['ID'];
};

export type QueryTaskEventArgs = {
  id: Scalars['ID'];
};

export type QueryTaskEventCommentsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  taskEventId: Scalars['ID'];
};

export type QueryTasksEventsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  onDate?: Maybe<Scalars['ISO8601DateTime']>;
  fromDate?: Maybe<Scalars['ISO8601DateTime']>;
  toDate?: Maybe<Scalars['ISO8601DateTime']>;
  categoryId?: Maybe<Scalars['ID']>;
  localityId?: Maybe<Scalars['ID']>;
};

export type QueryUserArgs = {
  id: Scalars['ID'];
};

export type QueryUsersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Role>>;
  currentLocalityIds?: Maybe<Array<Scalars['ID']>>;
  localityIds?: Maybe<Array<Scalars['ID']>>;
};

export type QuickbloxSessionToken = {
  __typename?: 'QuickbloxSessionToken';
  token?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of ReadTaskEventComments */
export type ReadTaskEventCommentsInput = {
  taskEventId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ReadTaskEventComments */
export type ReadTaskEventCommentsPayload = {
  __typename?: 'ReadTaskEventCommentsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
  task?: Maybe<TaskEvent>;
};

export type RecurrenceAttributes = {
  recurrenceId?: Maybe<Scalars['ID']>;
  frequency: TaskRecurrenceFrequencyAttributes;
  firstOccurrenceAt: Scalars['ISO8601Date'];
};

/** Autogenerated input type of RefreshToken */
export type RefreshTokenInput = {
  refreshToken: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of RefreshToken */
export type RefreshTokenPayload = {
  __typename?: 'RefreshTokenPayload';
  accessToken?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<ValidationError>;
  refreshToken?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export enum Role {
  Admin = 'ADMIN',
  Manager = 'MANAGER',
  Aquatechnician = 'AQUATECHNICIAN',
}

/** Autogenerated input type of SendChatNotification */
export type SendChatNotificationInput = {
  quickbloxUsersIds: Array<Scalars['String']>;
  message: Scalars['String'];
  dialogId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of SendChatNotification */
export type SendChatNotificationPayload = {
  __typename?: 'SendChatNotificationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of SendFeedback */
export type SendFeedbackInput = {
  note: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of SendFeedback */
export type SendFeedbackPayload = {
  __typename?: 'SendFeedbackPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of SetChecklistTaskUpdateStatus */
export type SetChecklistTaskUpdateStatusInput = {
  id: Scalars['ID'];
  taskEventId: Scalars['ID'];
  status: ChecklistTaskUpdateStatus;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of SetChecklistTaskUpdateStatus */
export type SetChecklistTaskUpdateStatusPayload = {
  __typename?: 'SetChecklistTaskUpdateStatusPayload';
  checklistTask?: Maybe<ChecklistTask>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of SetTaskEventStatus */
export type SetTaskEventStatusInput = {
  taskEventId: Scalars['ID'];
  status: TaskStatus;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of SetTaskEventStatus */
export type SetTaskEventStatusPayload = {
  __typename?: 'SetTaskEventStatusPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
  task?: Maybe<TaskEvent>;
};

/** Autogenerated input type of SetUserNotifications */
export type SetUserNotificationsInput = {
  sendNotifications: Scalars['Boolean'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of SetUserNotifications */
export type SetUserNotificationsPayload = {
  __typename?: 'SetUserNotificationsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
  user?: Maybe<User>;
};

/** Autogenerated input type of SignOut */
export type SignOutInput = {
  refreshToken: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of SignOut */
export type SignOutPayload = {
  __typename?: 'SignOutPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

export type Subscription = {
  __typename?: 'Subscription';
  checklistTaskStatusUpdated: ChecklistTaskStatusUpdatedPayload;
  taskCommentCreated: TaskCommentCreatedPayload;
  taskCommentStarred: TaskCommentStarredPayload;
  taskCommentUnstarred: TaskCommentUnstarredPayload;
  taskCommentUpdated: TaskCommentUpdatedPayload;
  taskEventCreated: TaskEventCreatedPayload;
  taskEventDeleted: TaskEventDeletedPayload;
  taskEventUpdated: TaskEventUpdatedPayload;
  userStatusUpdated: UserStatusUpdatedPayload;
};

export type SubscriptionChecklistTaskStatusUpdatedArgs = {
  taskEventId: Scalars['ID'];
};

export type SubscriptionTaskCommentCreatedArgs = {
  taskEventId: Scalars['ID'];
};

export type SubscriptionTaskCommentStarredArgs = {
  taskEventId: Scalars['ID'];
};

export type SubscriptionTaskCommentUnstarredArgs = {
  taskEventId: Scalars['ID'];
};

export type SubscriptionTaskCommentUpdatedArgs = {
  taskEventId: Scalars['ID'];
};

export type SubscriptionTaskEventCreatedArgs = {
  localityId: Scalars['ID'];
};

export type SubscriptionTaskEventDeletedArgs = {
  localityId: Scalars['ID'];
};

export type SubscriptionTaskEventUpdatedArgs = {
  localityId: Scalars['ID'];
};

/** Task */
export type Task = {
  __typename?: 'Task';
  attachments?: Maybe<Array<ImageAttachment>>;
  canDestroy: AuthorizationResult;
  canEdit: AuthorizationResult;
  category: Category;
  checklistTasks: Array<ChecklistTask>;
  createdAt: Scalars['ISO8601DateTime'];
  createdBy: User;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastOccurrenceAt: Scalars['ISO8601Date'];
  locality: Locality;
  priority: TaskPriority;
  recurrences: Array<TaskRecurrence>;
  startAt: Scalars['ISO8601Date'];
  title: Scalars['String'];
  units: Array<Bur>;
};

export type TaskAttributes = {
  title: Scalars['String'];
  description: Scalars['String'];
  startAt: Scalars['ISO8601Date'];
  lastOccurrenceAt: Scalars['ISO8601Date'];
  localityId: Scalars['ID'];
  categoryId: Scalars['ID'];
  priority: TaskPriority;
  recurrences: Array<RecurrenceAttributes>;
  checklistTasks?: Maybe<Array<ChecklistTaskAttributes>>;
  /** Accepts only burs with name and hasMerd: true */
  unitIds: Array<Scalars['ID']>;
  /** Key from S3 direct upload, argument will be ignored in updateTask mutation */
  attachments?: Maybe<Array<DirectUploadAttributes>>;
};

/** Autogenerated return type of TaskCommentCreated */
export type TaskCommentCreatedPayload = {
  __typename?: 'TaskCommentCreatedPayload';
  comment: Comment;
};

/** Autogenerated return type of TaskCommentStarred */
export type TaskCommentStarredPayload = {
  __typename?: 'TaskCommentStarredPayload';
  comment: Comment;
};

/** Autogenerated return type of TaskCommentUnstarred */
export type TaskCommentUnstarredPayload = {
  __typename?: 'TaskCommentUnstarredPayload';
  comment: Comment;
};

/** Autogenerated return type of TaskCommentUpdated */
export type TaskCommentUpdatedPayload = {
  __typename?: 'TaskCommentUpdatedPayload';
  comment: Comment;
};

export enum TaskDeletionScope {
  All = 'ALL',
  One = 'ONE',
}

/** TaskEvent */
export type TaskEvent = {
  __typename?: 'TaskEvent';
  canComplete: AuthorizationResult;
  canDestroy: AuthorizationResult;
  canEdit: AuthorizationResult;
  category?: Maybe<Category>;
  checklistTasks?: Maybe<Array<ChecklistTask>>;
  createdBy: User;
  description?: Maybe<Scalars['String']>;
  details: Details;
  happeningAt?: Maybe<Scalars['ISO8601Date']>;
  id: Scalars['ID'];
  lastOccurrenceAt?: Maybe<Scalars['ISO8601Date']>;
  locality?: Maybe<Locality>;
  priority?: Maybe<TaskPriority>;
  recurrences?: Maybe<Array<TaskRecurrence>>;
  shouldHappenAt?: Maybe<Scalars['ISO8601Date']>;
  startAt?: Maybe<Scalars['ISO8601Date']>;
  task: Task;
  title?: Maybe<Scalars['String']>;
  units: Array<Bur>;
  updatedBy?: Maybe<User>;
};

/** The connection type for TaskEvent. */
export type TaskEventConnection = {
  __typename?: 'TaskEventConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TaskEventEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<TaskEvent>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated return type of TaskEventCreated */
export type TaskEventCreatedPayload = {
  __typename?: 'TaskEventCreatedPayload';
  tasks: Array<TaskEvent>;
};

/** Autogenerated return type of TaskEventDeleted */
export type TaskEventDeletedPayload = {
  __typename?: 'TaskEventDeletedPayload';
  tasks: Array<TaskEvent>;
};

/** An edge in a connection. */
export type TaskEventEdge = {
  __typename?: 'TaskEventEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<TaskEvent>;
};

/** Autogenerated return type of TaskEventUpdated */
export type TaskEventUpdatedPayload = {
  __typename?: 'TaskEventUpdatedPayload';
  tasks: Array<TaskEvent>;
};

export type TaskFrequency = {
  __typename?: 'TaskFrequency';
  period: TaskRecurrencePeriod;
  value: Scalars['Int'];
};

export enum TaskPriority {
  Low = 'LOW',
  Normal = 'NORMAL',
  High = 'HIGH',
}

/** TaskRecurrence */
export type TaskRecurrence = {
  __typename?: 'TaskRecurrence';
  firstOccurrenceAt: Scalars['ISO8601Date'];
  frequency: TaskFrequency;
  id: Scalars['ID'];
  weekday: Weekday;
};

export type TaskRecurrenceFrequencyAttributes = {
  value: Scalars['Int'];
  period: TaskRecurrencePeriod;
};

export enum TaskRecurrencePeriod {
  Once = 'ONCE',
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH',
  Year = 'YEAR',
}

export enum TaskStatus {
  Todo = 'TODO',
  Done = 'DONE',
}

export enum TaskUpdateScope {
  All = 'ALL',
  Future = 'FUTURE',
  One = 'ONE',
}

/** Autogenerated input type of UpdateComment */
export type UpdateCommentInput = {
  id: Scalars['ID'];
  attributes: CommentAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateComment */
export type UpdateCommentPayload = {
  __typename?: 'UpdateCommentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  comment?: Maybe<Comment>;
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

export type UpdateDeadfishAttributes = {
  totalDeadfishNumber: Scalars['Int'];
  unitId: Scalars['ID'];
  deadfishes?: Maybe<Array<DeadfishAttributes>>;
};

/** Autogenerated input type of UpdateLocality */
export type UpdateLocalityInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  managerId?: Maybe<Scalars['ID']>;
  aquatechnicianIds: Array<Scalars['ID']>;
  grid: LocalityGrid;
  feederIndex: Scalars['Int'];
  burs?: Maybe<Array<BursAttributes>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateLocality */
export type UpdateLocalityPayload = {
  __typename?: 'UpdateLocalityPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<ValidationError>;
  locality?: Maybe<Locality>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateMyStatus */
export type UpdateMyStatusInput = {
  status: UserStatus;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateMyStatus */
export type UpdateMyStatusPayload = {
  __typename?: 'UpdateMyStatusPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<ValidationError>;
  me: User;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateTaskCategory */
export type UpdateTaskCategoryInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  kind: CategoryKind;
  checklistTasks?: Maybe<Array<ChecklistTaskAttributes>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateTaskCategory */
export type UpdateTaskCategoryPayload = {
  __typename?: 'UpdateTaskCategoryPayload';
  category?: Maybe<Category>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateTaskEventDeadfishes */
export type UpdateTaskEventDeadfishesInput = {
  taskEventId: Scalars['ID'];
  attributes: Array<UpdateDeadfishAttributes>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateTaskEventDeadfishes */
export type UpdateTaskEventDeadfishesPayload = {
  __typename?: 'UpdateTaskEventDeadfishesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
  taskEvent?: Maybe<TaskEvent>;
};

/** Autogenerated input type of UpdateTask */
export type UpdateTaskInput = {
  taskEventId: Scalars['ID'];
  scope: TaskUpdateScope;
  attributes: TaskAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateTask */
export type UpdateTaskPayload = {
  __typename?: 'UpdateTaskPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
  task?: Maybe<Task>;
};

/** Autogenerated input type of UpdateUser */
export type UpdateUserInput = {
  id: Scalars['ID'];
  attributes: UserAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateUser */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<ValidationError>;
  success: Scalars['Boolean'];
  user?: Maybe<User>;
};

/** User */
export type User = {
  __typename?: 'User';
  canCreateChecklistTemplate?: Maybe<AuthorizationResult>;
  canCreateComment?: Maybe<AuthorizationResult>;
  canCreateLocality?: Maybe<AuthorizationResult>;
  canCreateTask?: Maybe<AuthorizationResult>;
  canCreateTaskCategory?: Maybe<AuthorizationResult>;
  canDestroy: AuthorizationResult;
  canEdit: AuthorizationResult;
  currentLocality?: Maybe<Locality>;
  email?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  localities: Array<Locality>;
  name?: Maybe<Scalars['String']>;
  permittedUserArguments?: Maybe<AuthorizedArguments>;
  phoneNumber?: Maybe<Scalars['E164PhoneNumber']>;
  quickbloxId?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  status: UserStatus;
  tasksEvents?: Maybe<TaskEventConnection>;
  username?: Maybe<Scalars['String']>;
};

/** User */
export type UserTasksEventsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  onDate?: Maybe<Scalars['ISO8601DateTime']>;
  toDate?: Maybe<Scalars['ISO8601DateTime']>;
  fromDate?: Maybe<Scalars['ISO8601DateTime']>;
  categoryId?: Maybe<Scalars['ID']>;
  localityId?: Maybe<Scalars['ID']>;
};

export type UserAttributes = {
  name?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  phoneNumber?: Maybe<Scalars['E164PhoneNumber']>;
  currentLocalityId?: Maybe<Scalars['ID']>;
};

/** The connection type for User. */
export type UserConnection = {
  __typename?: 'UserConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<User>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<User>;
};

export enum UserStatus {
  Offline = 'OFFLINE',
  Online = 'ONLINE',
}

/** Autogenerated return type of UserStatusUpdated */
export type UserStatusUpdatedPayload = {
  __typename?: 'UserStatusUpdatedPayload';
  user: User;
};

/** Validation error */
export type ValidationError = {
  __typename?: 'ValidationError';
  field?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export enum Weekday {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
}

export type CreateChecklistTemplateMutationVariables = Exact<{
  name: Scalars['String'];
  checklistTemplateTasks: Array<ChecklistTemplateTaskAttributes>;
}>;

export type CreateChecklistTemplateMutation = { __typename?: 'Mutation' } & {
  createChecklistTemplate?: Maybe<
    { __typename?: 'CreateChecklistTemplatePayload' } & Pick<CreateChecklistTemplatePayload, 'success'> & {
        checklistTemplate?: Maybe<
          { __typename?: 'ChecklistTemplate' } & Pick<ChecklistTemplate, 'id' | 'name'> & {
              checklistTemplateTasks: Array<
                { __typename?: 'ChecklistTemplateTask' } & Pick<ChecklistTemplateTask, 'value'>
              >;
            }
        >;
        errors: Array<{ __typename?: 'ValidationError' } & Pick<ValidationError, 'message' | 'field'>>;
      }
  >;
};

export type CreateLocalityMutationVariables = Exact<{
  name: Scalars['String'];
  managerId?: Maybe<Scalars['ID']>;
  grid: LocalityGrid;
  aquatechnicianIds: Array<Scalars['ID']>;
  burs?: Maybe<Array<BursAttributes>>;
  feederIndex: Scalars['Int'];
}>;

export type CreateLocalityMutation = { __typename?: 'Mutation' } & {
  createLocality?: Maybe<
    { __typename?: 'CreateLocalityPayload' } & Pick<CreateLocalityPayload, 'success'> & {
        locality?: Maybe<{ __typename?: 'Locality' } & LocalityDetailsFragment>;
        errors: Array<{ __typename?: 'ValidationError' } & Pick<ValidationError, 'message' | 'field'>>;
      }
  >;
};

export type CreateTaskMutationVariables = Exact<{
  title: Scalars['String'];
  description: Scalars['String'];
  startAt: Scalars['ISO8601Date'];
  lastOccurrenceAt: Scalars['ISO8601Date'];
  localityId: Scalars['ID'];
  categoryId: Scalars['ID'];
  priority: TaskPriority;
  recurrences: Array<RecurrenceAttributes>;
  checklistTasks?: Maybe<Array<ChecklistTaskAttributes>>;
  unitIds: Array<Scalars['ID']>;
  attachments?: Maybe<Array<DirectUploadAttributes>>;
}>;

export type CreateTaskMutation = { __typename?: 'Mutation' } & {
  createTask?: Maybe<
    { __typename?: 'CreateTaskPayload' } & Pick<CreateTaskPayload, 'success'> & {
        errors: Array<{ __typename?: 'ValidationError' } & Pick<ValidationError, 'message' | 'field'>>;
      }
  >;
};

export type CreateUserDeviceTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type CreateUserDeviceTokenMutation = { __typename?: 'Mutation' } & {
  createUserDeviceToken?: Maybe<
    { __typename?: 'CreateDeviceTokenPayload' } & Pick<CreateDeviceTokenPayload, 'success'> & {
        errors: Array<{ __typename?: 'ValidationError' } & Pick<ValidationError, 'message' | 'field'>>;
      }
  >;
};

export type DeleteChecklistTemplateMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteChecklistTemplateMutation = { __typename?: 'Mutation' } & {
  deleteChecklistTemplate?: Maybe<
    { __typename?: 'DeleteChecklistTemplatePayload' } & Pick<DeleteChecklistTemplatePayload, 'success'> & {
        errors: Array<{ __typename?: 'ValidationError' } & Pick<ValidationError, 'message' | 'field'>>;
      }
  >;
};

export type DeleteLocalityMutationVariables = Exact<{
  localityId: Scalars['ID'];
}>;

export type DeleteLocalityMutation = { __typename?: 'Mutation' } & {
  deleteLocality?: Maybe<{ __typename?: 'DeleteLocalityPayload' } & Pick<DeleteLocalityPayload, 'success'>>;
};

export type DeleteTaskMutationVariables = Exact<{
  taskEventId: Scalars['ID'];
  scope: TaskDeletionScope;
}>;

export type DeleteTaskMutation = { __typename?: 'Mutation' } & {
  deleteTask?: Maybe<{ __typename?: 'DeleteTaskPayload' } & Pick<DeleteTaskPayload, 'success'>>;
};

export type DeleteUserDeviceTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type DeleteUserDeviceTokenMutation = { __typename?: 'Mutation' } & {
  deleteUserDeviceToken?: Maybe<
    { __typename?: 'DeleteDeviceTokenPayload' } & Pick<DeleteDeviceTokenPayload, 'success'> & {
        errors: Array<{ __typename?: 'ValidationError' } & Pick<ValidationError, 'message' | 'field'>>;
      }
  >;
};

export type ExportTaskCsvMutationVariables = Exact<{
  email: Scalars['String'];
  taskEventId: Scalars['ID'];
}>;

export type ExportTaskCsvMutation = { __typename?: 'Mutation' } & {
  exportTaskCsv?: Maybe<
    { __typename?: 'ExportTaskCsvPayload' } & Pick<ExportTaskCsvPayload, 'success'> & {
        errors: Array<{ __typename?: 'ValidationError' } & Pick<ValidationError, 'message' | 'field'>>;
      }
  >;
};

export type MarkTaskEventUnitsMutationVariables = Exact<{
  taskEventId: Scalars['ID'];
  doneIds?: Maybe<Array<Scalars['ID']>>;
  notDoneIds?: Maybe<Array<Scalars['ID']>>;
}>;

export type MarkTaskEventUnitsMutation = { __typename?: 'Mutation' } & {
  markTaskEventUnits?: Maybe<
    { __typename?: 'MarkTaskEventUnitsPayload' } & Pick<MarkTaskEventUnitsPayload, 'success'> & {
        errors: Array<{ __typename?: 'ValidationError' } & Pick<ValidationError, 'field' | 'message'>>;
        taskEvent?: Maybe<{ __typename?: 'TaskEvent' } & TaskEventDetailsFragment>;
      }
  >;
};

export type ReadTaskEventCommentsMutationVariables = Exact<{
  taskEventId: Scalars['ID'];
}>;

export type ReadTaskEventCommentsMutation = { __typename?: 'Mutation' } & {
  readTaskEventComments?: Maybe<
    { __typename?: 'ReadTaskEventCommentsPayload' } & Pick<ReadTaskEventCommentsPayload, 'success'> & {
        errors: Array<{ __typename?: 'ValidationError' } & Pick<ValidationError, 'field' | 'message'>>;
        task?: Maybe<{ __typename?: 'TaskEvent' } & TaskEventDetailsFragment>;
      }
  >;
};

export type RefreshTokenMutationVariables = Exact<{
  refresh_token: Scalars['String'];
}>;

export type RefreshTokenMutation = { __typename?: 'Mutation' } & {
  refreshToken?: Maybe<
    { __typename?: 'RefreshTokenPayload' } & Pick<RefreshTokenPayload, 'accessToken' | 'refreshToken' | 'success'> & {
        errors: Array<{ __typename?: 'ValidationError' } & Pick<ValidationError, 'message' | 'field'>>;
      }
  >;
};

export type SendChatNotificationsMutationVariables = Exact<{
  message: Scalars['String'];
  dialogId: Scalars['String'];
  quickbloxUsersIds: Array<Scalars['String']>;
}>;

export type SendChatNotificationsMutation = { __typename?: 'Mutation' } & {
  sendChatNotifications?: Maybe<
    { __typename?: 'SendChatNotificationPayload' } & Pick<SendChatNotificationPayload, 'success'> & {
        errors: Array<{ __typename?: 'ValidationError' } & Pick<ValidationError, 'message' | 'field'>>;
      }
  >;
};

export type SendFeedbackMutationVariables = Exact<{
  note: Scalars['String'];
}>;

export type SendFeedbackMutation = { __typename?: 'Mutation' } & {
  sendFeedback?: Maybe<
    { __typename?: 'SendFeedbackPayload' } & Pick<SendFeedbackPayload, 'success'> & {
        errors: Array<{ __typename?: 'ValidationError' } & Pick<ValidationError, 'message' | 'field'>>;
      }
  >;
};

export type SetChecklistTaskUpdateStatusMutationVariables = Exact<{
  id: Scalars['ID'];
  task_event_id: Scalars['ID'];
  status: ChecklistTaskUpdateStatus;
}>;

export type SetChecklistTaskUpdateStatusMutation = { __typename?: 'Mutation' } & {
  setChecklistTaskUpdateStatus?: Maybe<
    { __typename?: 'SetChecklistTaskUpdateStatusPayload' } & Pick<SetChecklistTaskUpdateStatusPayload, 'success'> & {
        checklistTask?: Maybe<{ __typename?: 'ChecklistTask' } & Pick<ChecklistTask, 'id' | 'status'>>;
        errors: Array<{ __typename?: 'ValidationError' } & Pick<ValidationError, 'message' | 'field'>>;
      }
  >;
};

export type SetTaskEventStatusMutationVariables = Exact<{
  taskEventId: Scalars['ID'];
  status: TaskStatus;
}>;

export type SetTaskEventStatusMutation = { __typename?: 'Mutation' } & {
  setTaskEventStatus?: Maybe<
    { __typename?: 'SetTaskEventStatusPayload' } & Pick<SetTaskEventStatusPayload, 'success'> & {
        errors: Array<{ __typename?: 'ValidationError' } & Pick<ValidationError, 'field' | 'message'>>;
        task?: Maybe<{ __typename?: 'TaskEvent' } & TaskEventDetailsFragment>;
      }
  >;
};

export type SetUserNotificationsMutationVariables = Exact<{
  sendNotifications: Scalars['Boolean'];
}>;

export type SetUserNotificationsMutation = { __typename?: 'Mutation' } & {
  setUserNotifications?: Maybe<
    { __typename?: 'SetUserNotificationsPayload' } & Pick<SetUserNotificationsPayload, 'success'> & {
        errors: Array<{ __typename?: 'ValidationError' } & Pick<ValidationError, 'message' | 'field'>>;
      }
  >;
};

export type SignOutMutationVariables = Exact<{
  refresh_token: Scalars['String'];
}>;

export type SignOutMutation = { __typename?: 'Mutation' } & {
  signOut?: Maybe<
    { __typename?: 'SignOutPayload' } & Pick<SignOutPayload, 'success'> & {
        errors: Array<{ __typename?: 'ValidationError' } & Pick<ValidationError, 'message' | 'field'>>;
      }
  >;
};

export type UpdateCommentMutationVariables = Exact<{
  commentId: Scalars['ID'];
  value: Scalars['String'];
}>;

export type UpdateCommentMutation = { __typename?: 'Mutation' } & {
  updateComment?: Maybe<
    { __typename?: 'UpdateCommentPayload' } & Pick<UpdateCommentPayload, 'success'> & {
        errors: Array<{ __typename?: 'ValidationError' } & Pick<ValidationError, 'field' | 'message'>>;
        comment?: Maybe<{ __typename?: 'Comment' } & TaskCommentItemFragment>;
      }
  >;
};

export type UpdateLocalityMutationVariables = Exact<{
  localityId: Scalars['ID'];
  name: Scalars['String'];
  managerId?: Maybe<Scalars['ID']>;
  grid: LocalityGrid;
  aquatechnicianIds: Array<Scalars['ID']>;
  burs?: Maybe<Array<BursAttributes>>;
  feederIndex: Scalars['Int'];
}>;

export type UpdateLocalityMutation = { __typename?: 'Mutation' } & {
  updateLocality?: Maybe<
    { __typename?: 'UpdateLocalityPayload' } & Pick<UpdateLocalityPayload, 'success'> & {
        locality?: Maybe<{ __typename?: 'Locality' } & LocalityDetailsFragment>;
        errors: Array<{ __typename?: 'ValidationError' } & Pick<ValidationError, 'message' | 'field'>>;
      }
  >;
};

export type UpdateMyStatusMutationVariables = Exact<{
  status: UserStatus;
}>;

export type UpdateMyStatusMutation = { __typename?: 'Mutation' } & {
  updateMyStatus?: Maybe<
    { __typename?: 'UpdateMyStatusPayload' } & Pick<UpdateMyStatusPayload, 'success'> & {
        me: { __typename?: 'User' } & UserDetailsFragment;
      }
  >;
};

export type UpdateTaskMutationVariables = Exact<{
  taskEventId: Scalars['ID'];
  title: Scalars['String'];
  description: Scalars['String'];
  startAt: Scalars['ISO8601Date'];
  lastOccurrenceAt: Scalars['ISO8601Date'];
  localityId: Scalars['ID'];
  categoryId: Scalars['ID'];
  priority: TaskPriority;
  recurrences: Array<RecurrenceAttributes>;
  checklistTasks?: Maybe<Array<ChecklistTaskAttributes>>;
  unitIds: Array<Scalars['ID']>;
  scope: TaskUpdateScope;
  attachments?: Maybe<Array<DirectUploadAttributes>>;
}>;

export type UpdateTaskMutation = { __typename?: 'Mutation' } & {
  updateTask?: Maybe<
    { __typename?: 'UpdateTaskPayload' } & Pick<UpdateTaskPayload, 'success'> & {
        errors: Array<{ __typename?: 'ValidationError' } & Pick<ValidationError, 'message' | 'field'>>;
        task?: Maybe<{ __typename?: 'Task' } & TaskDetailsFragment>;
      }
  >;
};

export type UpdateTaskEventDeadfishesMutationVariables = Exact<{
  taskEventId: Scalars['ID'];
  attributes: Array<UpdateDeadfishAttributes>;
}>;

export type UpdateTaskEventDeadfishesMutation = { __typename?: 'Mutation' } & {
  updateTaskEventDeadfishes?: Maybe<
    { __typename?: 'UpdateTaskEventDeadfishesPayload' } & Pick<UpdateTaskEventDeadfishesPayload, 'success'> & {
        taskEvent?: Maybe<
          { __typename?: 'TaskEvent' } & Pick<TaskEvent, 'id'> & {
              details: { __typename?: 'Details' } & {
                deadfishes: Array<
                  { __typename?: 'Deadfish' } & Pick<Deadfish, 'id' | 'value'> & {
                      category: { __typename?: 'DeadfishCategory' } & Pick<DeadfishCategory, 'id' | 'name'>;
                    }
                >;
              };
            }
        >;
        errors: Array<{ __typename?: 'ValidationError' } & Pick<ValidationError, 'message' | 'field'>>;
      }
  >;
};

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
  currentLocalityId?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['E164PhoneNumber']>;
  role?: Maybe<Role>;
}>;

export type UpdateUserMutation = { __typename?: 'Mutation' } & {
  updateUser?: Maybe<
    { __typename?: 'UpdateUserPayload' } & Pick<UpdateUserPayload, 'success'> & {
        user?: Maybe<{ __typename?: 'User' } & UserDetailsFragment>;
        errors: Array<{ __typename?: 'ValidationError' } & Pick<ValidationError, 'message' | 'field'>>;
      }
  >;
};

export type GetAllCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllCategoriesQuery = { __typename?: 'Query' } & {
  categories?: Maybe<
    { __typename?: 'CategoryConnection' } & {
      nodes?: Maybe<Array<Maybe<{ __typename?: 'Category' } & CategoryItemFragment>>>;
    }
  >;
};

export type GetCategoryDetailsQueryVariables = Exact<{
  categoryId: Scalars['ID'];
}>;

export type GetCategoryDetailsQuery = { __typename?: 'Query' } & {
  category?: Maybe<
    { __typename?: 'Category' } & {
      checklistTasks: Array<{ __typename?: 'ChecklistTask' } & Pick<ChecklistTask, 'id' | 'order' | 'value'>>;
    } & CategoryItemFragment
  >;
};

export type GetDeadfishCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type GetDeadfishCategoriesQuery = { __typename?: 'Query' } & {
  deadfishCategories?: Maybe<
    { __typename?: 'DeadfishCategoryConnection' } & {
      nodes?: Maybe<Array<Maybe<{ __typename?: 'DeadfishCategory' } & Pick<DeadfishCategory, 'id' | 'name'>>>>;
    }
  >;
};

export type CategoryItemFragment = { __typename?: 'Category' } & Pick<Category, 'id' | 'name' | 'kind' | 'default'>;

export type ChecklistTemplateQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type ChecklistTemplateQuery = { __typename?: 'Query' } & {
  checklistTemplate?: Maybe<
    { __typename?: 'ChecklistTemplate' } & Pick<ChecklistTemplate, 'id' | 'name'> & {
        checklistTemplateTasks: Array<
          { __typename?: 'ChecklistTemplateTask' } & Pick<ChecklistTemplateTask, 'id' | 'value'>
        >;
        canDestroy: { __typename?: 'AuthorizationResult' } & Pick<AuthorizationResult, 'value'>;
      }
  >;
};

export type ChecklistTemplatesQueryVariables = Exact<{ [key: string]: never }>;

export type ChecklistTemplatesQuery = { __typename?: 'Query' } & {
  checklistTemplates?: Maybe<
    { __typename?: 'ChecklistTemplateConnection' } & {
      edges?: Maybe<
        Array<
          Maybe<
            { __typename?: 'ChecklistTemplateEdge' } & {
              node?: Maybe<
                { __typename?: 'ChecklistTemplate' } & Pick<ChecklistTemplate, 'id' | 'name'> & {
                    checklistTemplateTasks: Array<
                      { __typename?: 'ChecklistTemplateTask' } & Pick<ChecklistTemplateTask, 'id' | 'value'>
                    >;
                    canDestroy: { __typename?: 'AuthorizationResult' } & Pick<AuthorizationResult, 'value'>;
                  }
              >;
            }
          >
        >
      >;
    }
  >;
};

export type GetAllLocalitiesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllLocalitiesQuery = { __typename?: 'Query' } & {
  localities?: Maybe<
    { __typename?: 'LocalityConnection' } & {
      nodes?: Maybe<Array<Maybe<{ __typename?: 'Locality' } & LocalityItemFragment>>>;
    }
  >;
};

export type GetLocalityDetailsQueryVariables = Exact<{
  localityId: Scalars['ID'];
}>;

export type GetLocalityDetailsQuery = { __typename?: 'Query' } & {
  locality?: Maybe<{ __typename?: 'Locality' } & LocalityDetailsFragment>;
};

export type LocalityItemFragment = { __typename?: 'Locality' } & Pick<Locality, 'id' | 'name'> & {
    manager?: Maybe<{ __typename?: 'User' } & UserItemFragment>;
  };

export type LocalityDetailsFragment = { __typename?: 'Locality' } & Pick<Locality, 'id' | 'name'> & {
    manager?: Maybe<{ __typename?: 'User' } & UserItemFragment>;
    aquatechnicians: { __typename?: 'UserConnection' } & {
      nodes?: Maybe<Array<Maybe<{ __typename?: 'User' } & UserItemFragment>>>;
    };
    arrangement: { __typename?: 'Arrangement' } & Pick<Arrangement, 'id' | 'grid' | 'feederIndex'> & {
        burs: Array<{ __typename?: 'Bur' } & Pick<Bur, 'hasMerd' | 'id' | 'position' | 'name' | 'onGridIndex'>>;
      };
    canEdit: { __typename?: 'AuthorizationResult' } & Pick<AuthorizationResult, 'value'>;
    canDestroy: { __typename?: 'AuthorizationResult' } & Pick<AuthorizationResult, 'value'>;
  };

export type GetMeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeQuery = { __typename?: 'Query' } & {
  me?: Maybe<
    { __typename?: 'User' } & {
      canCreateTask?: Maybe<{ __typename?: 'AuthorizationResult' } & Pick<AuthorizationResult, 'value'>>;
      canCreateLocality?: Maybe<{ __typename?: 'AuthorizationResult' } & Pick<AuthorizationResult, 'value'>>;
      canCreateChecklistTemplate?: Maybe<{ __typename?: 'AuthorizationResult' } & Pick<AuthorizationResult, 'value'>>;
      permittedUserArguments?: Maybe<{ __typename?: 'AuthorizedArguments' } & Pick<AuthorizedArguments, 'value'>>;
    } & UserDetailsFragment
  >;
};

export type UserDetailsFragment = { __typename?: 'User' } & Pick<
  User,
  | 'id'
  | 'username'
  | 'name'
  | 'email'
  | 'firstName'
  | 'lastName'
  | 'externalId'
  | 'role'
  | 'status'
  | 'phoneNumber'
  | 'quickbloxId'
> & {
    canEdit: { __typename?: 'AuthorizationResult' } & Pick<AuthorizationResult, 'value'>;
    canDestroy: { __typename?: 'AuthorizationResult' } & Pick<AuthorizationResult, 'value'>;
    currentLocality?: Maybe<{ __typename?: 'Locality' } & Pick<Locality, 'id' | 'name'>>;
  };

export type GetTasksEventsDetailsQueryVariables = Exact<{
  date?: Maybe<Scalars['ISO8601DateTime']>;
  fromDate?: Maybe<Scalars['ISO8601DateTime']>;
  toDate?: Maybe<Scalars['ISO8601DateTime']>;
  localityId?: Maybe<Scalars['ID']>;
  categoryId?: Maybe<Scalars['ID']>;
}>;

export type GetTasksEventsDetailsQuery = { __typename?: 'Query' } & {
  tasksEvents?: Maybe<
    { __typename?: 'TaskEventConnection' } & {
      nodes?: Maybe<Array<Maybe<{ __typename?: 'TaskEvent' } & TaskEventDetailsFragment>>>;
    }
  >;
};

export type GetTaskEventDetailsQueryVariables = Exact<{
  taskEventId: Scalars['ID'];
}>;

export type GetTaskEventDetailsQuery = { __typename?: 'Query' } & {
  taskEvent?: Maybe<{ __typename?: 'TaskEvent' } & TaskEventDetailsFragment>;
};

export type TaskItemFragment = { __typename?: 'Task' } & Pick<Task, 'id' | 'title' | 'priority'> & {
    category: { __typename?: 'Category' } & Pick<Category, 'id' | 'name' | 'kind'>;
    units: Array<{ __typename?: 'Bur' } & Pick<Bur, 'id' | 'name' | 'hasMerd' | 'position' | 'onGridIndex'>>;
    locality: { __typename?: 'Locality' } & Pick<Locality, 'id'> & {
        arrangement: { __typename?: 'Arrangement' } & Pick<Arrangement, 'id' | 'grid'>;
      };
    attachments?: Maybe<
      Array<{ __typename?: 'ImageAttachment' } & Pick<ImageAttachment, 'fileName' | 'originalUrl' | 'thumbnailUrl'>>
    >;
    recurrences: Array<
      { __typename?: 'TaskRecurrence' } & Pick<TaskRecurrence, 'id' | 'firstOccurrenceAt' | 'weekday'> & {
          frequency: { __typename?: 'TaskFrequency' } & Pick<TaskFrequency, 'period' | 'value'>;
        }
    >;
  };

export type TaskDetailsFragment = { __typename?: 'Task' } & Pick<
  Task,
  'description' | 'startAt' | 'lastOccurrenceAt' | 'createdAt'
> & {
    canEdit: { __typename?: 'AuthorizationResult' } & Pick<AuthorizationResult, 'value'>;
    canDestroy: { __typename?: 'AuthorizationResult' } & Pick<AuthorizationResult, 'value'>;
    createdBy: { __typename?: 'User' } & Pick<User, 'id' | 'name'>;
    locality: { __typename?: 'Locality' } & Pick<Locality, 'name'> & {
        arrangement: { __typename?: 'Arrangement' } & Pick<Arrangement, 'feederIndex'>;
      };
    attachments?: Maybe<
      Array<{ __typename?: 'ImageAttachment' } & Pick<ImageAttachment, 'fileName' | 'originalUrl' | 'thumbnailUrl'>>
    >;
  } & TaskItemFragment;

export type TaskEventItemFragment = { __typename?: 'TaskEvent' } & Pick<
  TaskEvent,
  'id' | 'happeningAt' | 'shouldHappenAt' | 'startAt' | 'lastOccurrenceAt'
> & {
    details: { __typename?: 'Details' } & Pick<Details, 'id' | 'status'> & {
        doneUnits: Array<{ __typename?: 'Bur' } & Pick<Bur, 'id'>>;
      };
    task: { __typename?: 'Task' } & TaskItemFragment;
    updatedBy?: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'name'>>;
    checklistTasks?: Maybe<
      Array<{ __typename?: 'ChecklistTask' } & Pick<ChecklistTask, 'id' | 'order' | 'value' | 'status'>>
    >;
  };

export type TaskEventDetailsFragment = { __typename?: 'TaskEvent' } & {
  details: { __typename?: 'Details' } & {
    deadfishes: Array<
      { __typename?: 'Deadfish' } & Pick<Deadfish, 'id' | 'value'> & {
          unit: { __typename?: 'Bur' } & Pick<Bur, 'id' | 'name'>;
          category: { __typename?: 'DeadfishCategory' } & Pick<DeadfishCategory, 'id' | 'name'>;
        }
    >;
    unitsDeadfishNumbers: Array<
      { __typename?: 'DeadfishNumber' } & Pick<DeadfishNumber, 'totalDeadfishNumber'> & {
          unit: { __typename?: 'Bur' } & Pick<Bur, 'id'>;
        }
    >;
    comments?: Maybe<{ __typename?: 'CommentConnection' } & Pick<CommentConnection, 'totalCount' | 'notSeenCount'>>;
  };
  canComplete: { __typename?: 'AuthorizationResult' } & Pick<AuthorizationResult, 'value'>;
  canEdit: { __typename?: 'AuthorizationResult' } & Pick<AuthorizationResult, 'value'>;
  canDestroy: { __typename?: 'AuthorizationResult' } & Pick<AuthorizationResult, 'value'>;
  task: { __typename?: 'Task' } & TaskDetailsFragment;
  updatedBy?: Maybe<{ __typename?: 'User' } & Pick<User, 'id' | 'name'>>;
} & TaskEventItemFragment;

export type GetUserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetUserQuery = { __typename?: 'Query' } & {
  user?: Maybe<
    { __typename?: 'User' } & {
      localities: Array<{ __typename?: 'Locality' } & Pick<Locality, 'id' | 'name'>>;
    } & UserItemFragment
  >;
};

export type GetUsersQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Role>>;
  currentLocalityIds?: Maybe<Array<Scalars['ID']>>;
  localityIds?: Maybe<Array<Scalars['ID']>>;
}>;

export type GetUsersQuery = { __typename?: 'Query' } & {
  users?: Maybe<
    { __typename?: 'UserConnection' } & { nodes?: Maybe<Array<Maybe<{ __typename?: 'User' } & UserItemFragment>>> }
  >;
};

export type GetPaginatedUsersQueryVariables = Exact<{
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Role>>;
  currentLocalityIds?: Maybe<Array<Scalars['ID']>>;
  localityIds?: Maybe<Array<Scalars['ID']>>;
}>;

export type GetPaginatedUsersQuery = { __typename?: 'Query' } & {
  users?: Maybe<
    { __typename?: 'UserConnection' } & {
      edges?: Maybe<
        Array<
          Maybe<
            { __typename?: 'UserEdge' } & Pick<UserEdge, 'cursor'> & {
                node?: Maybe<{ __typename?: 'User' } & UserItemFragment>;
              }
          >
        >
      >;
      pageInfo: { __typename?: 'PageInfo' } & StartToEndPageInfoFragment;
    }
  >;
};

export type UserItemFragment = { __typename?: 'User' } & Pick<
  User,
  'id' | 'firstName' | 'lastName' | 'phoneNumber' | 'role' | 'status' | 'email' | 'quickbloxId'
> & {
    canEdit: { __typename?: 'AuthorizationResult' } & Pick<AuthorizationResult, 'value'>;
    canDestroy: { __typename?: 'AuthorizationResult' } & Pick<AuthorizationResult, 'value'>;
    currentLocality?: Maybe<{ __typename?: 'Locality' } & Pick<Locality, 'id' | 'name'>>;
  };

export type StartToEndPageInfoFragment = { __typename?: 'PageInfo' } & Pick<PageInfo, 'endCursor' | 'hasNextPage'>;

export type PresignUploadQueryVariables = Exact<{
  fileName: Scalars['String'];
  fileType: Scalars['String'];
  objectType: PresignObject;
}>;

export type PresignUploadQuery = { __typename?: 'Query' } & {
  presignUpload?: Maybe<{ __typename?: 'PresignedUpload' } & Pick<PresignedUpload, 'fields' | 'url'>>;
};

export type QuickbloxSessionTokenQueryVariables = Exact<{ [key: string]: never }>;

export type QuickbloxSessionTokenQuery = { __typename?: 'Query' } & {
  quickbloxSessionToken?: Maybe<{ __typename?: 'QuickbloxSessionToken' } & Pick<QuickbloxSessionToken, 'token'>>;
};

export type GetTaskEventCommentsQueryVariables = Exact<{
  taskEventId: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
}>;

export type GetTaskEventCommentsQuery = { __typename?: 'Query' } & {
  taskEventComments?: Maybe<
    { __typename?: 'CommentConnection' } & {
      edges?: Maybe<
        Array<
          Maybe<{ __typename?: 'CommentEdge' } & { node?: Maybe<{ __typename?: 'Comment' } & TaskCommentItemFragment> }>
        >
      >;
      pageInfo: { __typename?: 'PageInfo' } & StartToEndPageInfoFragment;
    }
  >;
};

export type CreateCommentMutationVariables = Exact<{
  taskEventId: Scalars['ID'];
  value: Scalars['String'];
  attachments?: Maybe<Array<DirectUploadAttributes>>;
}>;

export type CreateCommentMutation = { __typename?: 'Mutation' } & {
  createComment?: Maybe<
    { __typename?: 'CreateCommentPayload' } & Pick<CreateCommentPayload, 'success'> & {
        comment?: Maybe<{ __typename?: 'Comment' } & TaskCommentItemFragment>;
      }
  >;
};

export type MarkCommentMutationVariables = Exact<{
  commentId: Scalars['ID'];
  starred: Scalars['Boolean'];
}>;

export type MarkCommentMutation = { __typename?: 'Mutation' } & {
  markComment?: Maybe<
    { __typename?: 'MarkCommentPayload' } & Pick<MarkCommentPayload, 'success'> & {
        comment?: Maybe<{ __typename?: 'Comment' } & TaskCommentItemFragment>;
      }
  >;
};

export type TaskCommentItemFragment = { __typename?: 'Comment' } & Pick<
  Comment,
  'id' | 'starred' | 'value' | 'createdAt'
> & {
    author: { __typename?: 'User' } & UserItemFragment;
    canEdit: { __typename?: 'AuthorizationResult' } & Pick<AuthorizationResult, 'value'>;
    canDestroy: { __typename?: 'AuthorizationResult' } & Pick<AuthorizationResult, 'value'>;
    attachments?: Maybe<
      Array<{ __typename?: 'ImageAttachment' } & Pick<ImageAttachment, 'fileName' | 'originalUrl' | 'thumbnailUrl'>>
    >;
  };

export type WatchChecklistTaskStatusUpdatedSubscriptionVariables = Exact<{
  taskEventId: Scalars['ID'];
}>;

export type WatchChecklistTaskStatusUpdatedSubscription = { __typename?: 'Subscription' } & {
  checklistTaskStatusUpdated: { __typename?: 'ChecklistTaskStatusUpdatedPayload' } & {
    checklistTask: { __typename?: 'ChecklistTask' } & Pick<ChecklistTask, 'id' | 'status' | 'value'>;
  };
};

export type WatchTaskCommentCreatedSubscriptionVariables = Exact<{
  taskEventId: Scalars['ID'];
}>;

export type WatchTaskCommentCreatedSubscription = { __typename?: 'Subscription' } & {
  taskCommentCreated: { __typename?: 'TaskCommentCreatedPayload' } & {
    comment: { __typename?: 'Comment' } & TaskCommentItemFragment;
  };
};

export type WatchTaskCommentStarredSubscriptionVariables = Exact<{
  taskEventId: Scalars['ID'];
}>;

export type WatchTaskCommentStarredSubscription = { __typename?: 'Subscription' } & {
  taskCommentStarred: { __typename?: 'TaskCommentStarredPayload' } & {
    comment: { __typename?: 'Comment' } & TaskCommentItemFragment;
  };
};

export type WatchTaskCommentUnstarredSubscriptionVariables = Exact<{
  taskEventId: Scalars['ID'];
}>;

export type WatchTaskCommentUnstarredSubscription = { __typename?: 'Subscription' } & {
  taskCommentUnstarred: { __typename?: 'TaskCommentUnstarredPayload' } & {
    comment: { __typename?: 'Comment' } & TaskCommentItemFragment;
  };
};

export type WatchTaskCommentUpdatedSubscriptionVariables = Exact<{
  taskEventId: Scalars['ID'];
}>;

export type WatchTaskCommentUpdatedSubscription = { __typename?: 'Subscription' } & {
  taskCommentUpdated: { __typename?: 'TaskCommentUpdatedPayload' } & {
    comment: { __typename?: 'Comment' } & TaskCommentItemFragment;
  };
};

export type WatchTaskEventCreatedSubscriptionVariables = Exact<{
  localityId: Scalars['ID'];
}>;

export type WatchTaskEventCreatedSubscription = { __typename?: 'Subscription' } & {
  taskEventCreated: { __typename?: 'TaskEventCreatedPayload' } & {
    tasks: Array<{ __typename?: 'TaskEvent' } & TaskEventDetailsFragment>;
  };
};

export type WatchTaskEventDeletedSubscriptionVariables = Exact<{
  localityId: Scalars['ID'];
}>;

export type WatchTaskEventDeletedSubscription = { __typename?: 'Subscription' } & {
  taskEventDeleted: { __typename?: 'TaskEventDeletedPayload' } & {
    tasks: Array<{ __typename?: 'TaskEvent' } & TaskEventDetailsFragment>;
  };
};

export type WatchTaskEventUpdatedSubscriptionVariables = Exact<{
  localityId: Scalars['ID'];
}>;

export type WatchTaskEventUpdatedSubscription = { __typename?: 'Subscription' } & {
  taskEventUpdated: { __typename?: 'TaskEventUpdatedPayload' } & {
    tasks: Array<{ __typename?: 'TaskEvent' } & TaskEventDetailsFragment>;
  };
};

export type WatchUsersSubscriptionVariables = Exact<{ [key: string]: never }>;

export type WatchUsersSubscription = { __typename?: 'Subscription' } & {
  userStatusUpdated: { __typename?: 'UserStatusUpdatedPayload' } & {
    user: { __typename?: 'User' } & Pick<User, 'id' | 'status'>;
  };
};

export const CategoryItemFragmentDoc = gql`
  fragment CategoryItem on Category {
    id
    name
    kind
    default
  }
`;
export const UserItemFragmentDoc = gql`
  fragment UserItem on User {
    id
    firstName
    lastName
    phoneNumber
    role
    status
    email
    quickbloxId
    canEdit {
      value
    }
    canDestroy {
      value
    }
    currentLocality {
      id
      name
    }
  }
`;
export const LocalityItemFragmentDoc = gql`
  fragment LocalityItem on Locality {
    id
    name
    manager {
      ...UserItem
    }
  }
  ${UserItemFragmentDoc}
`;
export const LocalityDetailsFragmentDoc = gql`
  fragment LocalityDetails on Locality {
    id
    name
    manager {
      ...UserItem
    }
    aquatechnicians {
      nodes {
        ...UserItem
      }
    }
    arrangement {
      id
      grid
      feederIndex
      burs {
        hasMerd
        id
        position
        name
        onGridIndex
      }
    }
    canEdit {
      value
    }
    canDestroy {
      value
    }
  }
  ${UserItemFragmentDoc}
`;
export const UserDetailsFragmentDoc = gql`
  fragment UserDetails on User {
    canEdit {
      value
    }
    canDestroy {
      value
    }
    id
    username
    name
    email
    firstName
    lastName
    externalId
    role
    status
    phoneNumber
    quickbloxId
    currentLocality {
      id
      name
    }
  }
`;
export const TaskItemFragmentDoc = gql`
  fragment TaskItem on Task {
    id
    title
    priority
    category {
      id
      name
      kind
    }
    units {
      id
      name
      hasMerd
      position
      onGridIndex
    }
    locality {
      id
      arrangement {
        id
        grid
      }
    }
    attachments {
      fileName
      originalUrl
      thumbnailUrl(width: 380)
    }
    recurrences {
      id
      firstOccurrenceAt
      weekday
      frequency {
        period
        value
      }
    }
  }
`;
export const TaskEventItemFragmentDoc = gql`
  fragment TaskEventItem on TaskEvent {
    id
    happeningAt
    shouldHappenAt
    startAt
    lastOccurrenceAt
    details {
      id
      status
      doneUnits {
        id
      }
    }
    task {
      ...TaskItem
    }
    updatedBy {
      id
      name
    }
    checklistTasks {
      id
      order
      value
      status
    }
  }
  ${TaskItemFragmentDoc}
`;
export const TaskDetailsFragmentDoc = gql`
  fragment TaskDetails on Task {
    ...TaskItem
    canEdit {
      value
    }
    canDestroy {
      value
    }
    description
    startAt
    lastOccurrenceAt
    createdAt
    createdBy {
      id
      name
    }
    locality {
      name
      arrangement {
        feederIndex
      }
    }
    attachments {
      fileName
      originalUrl
      thumbnailUrl(width: 380)
    }
  }
  ${TaskItemFragmentDoc}
`;
export const TaskEventDetailsFragmentDoc = gql`
  fragment TaskEventDetails on TaskEvent {
    ...TaskEventItem
    details {
      deadfishes {
        id
        unit {
          id
          name
        }
        value
        category {
          id
          name
        }
      }
      unitsDeadfishNumbers {
        totalDeadfishNumber
        unit {
          id
        }
      }
      comments {
        totalCount
        notSeenCount
      }
    }
    canComplete {
      value
    }
    canEdit {
      value
    }
    canDestroy {
      value
    }
    task {
      ...TaskDetails
    }
    updatedBy {
      id
      name
    }
  }
  ${TaskEventItemFragmentDoc}
  ${TaskDetailsFragmentDoc}
`;
export const StartToEndPageInfoFragmentDoc = gql`
  fragment StartToEndPageInfo on PageInfo {
    endCursor
    hasNextPage
  }
`;
export const TaskCommentItemFragmentDoc = gql`
  fragment TaskCommentItem on Comment {
    id
    starred
    value
    createdAt
    author {
      ...UserItem
    }
    canEdit {
      value
    }
    canDestroy {
      value
    }
    attachments {
      fileName
      originalUrl
      thumbnailUrl(width: 380)
    }
  }
  ${UserItemFragmentDoc}
`;
export const CreateChecklistTemplateDocument = gql`
  mutation createChecklistTemplate($name: String!, $checklistTemplateTasks: [ChecklistTemplateTaskAttributes!]!) {
    createChecklistTemplate(input: { name: $name, checklistTemplateTasks: $checklistTemplateTasks }) {
      checklistTemplate {
        id
        name
        checklistTemplateTasks {
          value
        }
      }
      errors {
        message
        field
      }
      success
    }
  }
`;
export type CreateChecklistTemplateMutationFn = Apollo.MutationFunction<
  CreateChecklistTemplateMutation,
  CreateChecklistTemplateMutationVariables
>;

/**
 * __useCreateChecklistTemplateMutation__
 *
 * To run a mutation, you first call `useCreateChecklistTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChecklistTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChecklistTemplateMutation, { data, loading, error }] = useCreateChecklistTemplateMutation({
 *   variables: {
 *      name: // value for 'name'
 *      checklistTemplateTasks: // value for 'checklistTemplateTasks'
 *   },
 * });
 */
export function useCreateChecklistTemplateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateChecklistTemplateMutation,
    CreateChecklistTemplateMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<CreateChecklistTemplateMutation, CreateChecklistTemplateMutationVariables>(
    CreateChecklistTemplateDocument,
    baseOptions,
  );
}
export type CreateChecklistTemplateMutationHookResult = ReturnType<typeof useCreateChecklistTemplateMutation>;
export type CreateChecklistTemplateMutationResult = Apollo.MutationResult<CreateChecklistTemplateMutation>;
export type CreateChecklistTemplateMutationOptions = Apollo.BaseMutationOptions<
  CreateChecklistTemplateMutation,
  CreateChecklistTemplateMutationVariables
>;
export const CreateLocalityDocument = gql`
  mutation createLocality(
    $name: String!
    $managerId: ID
    $grid: LocalityGrid!
    $aquatechnicianIds: [ID!]!
    $burs: [BursAttributes!]
    $feederIndex: Int!
  ) {
    createLocality(
      input: {
        name: $name
        managerId: $managerId
        grid: $grid
        aquatechnicianIds: $aquatechnicianIds
        burs: $burs
        feederIndex: $feederIndex
      }
    ) {
      locality {
        ...LocalityDetails
      }
      errors {
        message
        field
      }
      success
    }
  }
  ${LocalityDetailsFragmentDoc}
`;
export type CreateLocalityMutationFn = Apollo.MutationFunction<CreateLocalityMutation, CreateLocalityMutationVariables>;

/**
 * __useCreateLocalityMutation__
 *
 * To run a mutation, you first call `useCreateLocalityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocalityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocalityMutation, { data, loading, error }] = useCreateLocalityMutation({
 *   variables: {
 *      name: // value for 'name'
 *      managerId: // value for 'managerId'
 *      grid: // value for 'grid'
 *      aquatechnicianIds: // value for 'aquatechnicianIds'
 *      burs: // value for 'burs'
 *      feederIndex: // value for 'feederIndex'
 *   },
 * });
 */
export function useCreateLocalityMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateLocalityMutation, CreateLocalityMutationVariables>,
) {
  return ApolloReactHooks.useMutation<CreateLocalityMutation, CreateLocalityMutationVariables>(
    CreateLocalityDocument,
    baseOptions,
  );
}
export type CreateLocalityMutationHookResult = ReturnType<typeof useCreateLocalityMutation>;
export type CreateLocalityMutationResult = Apollo.MutationResult<CreateLocalityMutation>;
export type CreateLocalityMutationOptions = Apollo.BaseMutationOptions<
  CreateLocalityMutation,
  CreateLocalityMutationVariables
>;
export const CreateTaskDocument = gql`
  mutation createTask(
    $title: String!
    $description: String!
    $startAt: ISO8601Date!
    $lastOccurrenceAt: ISO8601Date!
    $localityId: ID!
    $categoryId: ID!
    $priority: TaskPriority!
    $recurrences: [RecurrenceAttributes!]!
    $checklistTasks: [ChecklistTaskAttributes!]
    $unitIds: [ID!]!
    $attachments: [DirectUploadAttributes!]
  ) {
    createTask(
      input: {
        attributes: {
          title: $title
          description: $description
          startAt: $startAt
          lastOccurrenceAt: $lastOccurrenceAt
          localityId: $localityId
          categoryId: $categoryId
          priority: $priority
          recurrences: $recurrences
          checklistTasks: $checklistTasks
          unitIds: $unitIds
          attachments: $attachments
        }
      }
    ) {
      errors {
        message
        field
      }
      success
    }
  }
`;
export type CreateTaskMutationFn = Apollo.MutationFunction<CreateTaskMutation, CreateTaskMutationVariables>;

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      startAt: // value for 'startAt'
 *      lastOccurrenceAt: // value for 'lastOccurrenceAt'
 *      localityId: // value for 'localityId'
 *      categoryId: // value for 'categoryId'
 *      priority: // value for 'priority'
 *      recurrences: // value for 'recurrences'
 *      checklistTasks: // value for 'checklistTasks'
 *      unitIds: // value for 'unitIds'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useCreateTaskMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTaskMutation, CreateTaskMutationVariables>,
) {
  return ApolloReactHooks.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(CreateTaskDocument, baseOptions);
}
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>;
export type CreateTaskMutationResult = Apollo.MutationResult<CreateTaskMutation>;
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<CreateTaskMutation, CreateTaskMutationVariables>;
export const CreateUserDeviceTokenDocument = gql`
  mutation createUserDeviceToken($token: String!) {
    createUserDeviceToken(input: { token: $token }) {
      errors {
        message
        field
      }
      success
    }
  }
`;
export type CreateUserDeviceTokenMutationFn = Apollo.MutationFunction<
  CreateUserDeviceTokenMutation,
  CreateUserDeviceTokenMutationVariables
>;

/**
 * __useCreateUserDeviceTokenMutation__
 *
 * To run a mutation, you first call `useCreateUserDeviceTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserDeviceTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserDeviceTokenMutation, { data, loading, error }] = useCreateUserDeviceTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useCreateUserDeviceTokenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateUserDeviceTokenMutation,
    CreateUserDeviceTokenMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<CreateUserDeviceTokenMutation, CreateUserDeviceTokenMutationVariables>(
    CreateUserDeviceTokenDocument,
    baseOptions,
  );
}
export type CreateUserDeviceTokenMutationHookResult = ReturnType<typeof useCreateUserDeviceTokenMutation>;
export type CreateUserDeviceTokenMutationResult = Apollo.MutationResult<CreateUserDeviceTokenMutation>;
export type CreateUserDeviceTokenMutationOptions = Apollo.BaseMutationOptions<
  CreateUserDeviceTokenMutation,
  CreateUserDeviceTokenMutationVariables
>;
export const DeleteChecklistTemplateDocument = gql`
  mutation deleteChecklistTemplate($id: ID!) {
    deleteChecklistTemplate(input: { id: $id }) {
      errors {
        message
        field
      }
      success
    }
  }
`;
export type DeleteChecklistTemplateMutationFn = Apollo.MutationFunction<
  DeleteChecklistTemplateMutation,
  DeleteChecklistTemplateMutationVariables
>;

/**
 * __useDeleteChecklistTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteChecklistTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChecklistTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChecklistTemplateMutation, { data, loading, error }] = useDeleteChecklistTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteChecklistTemplateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteChecklistTemplateMutation,
    DeleteChecklistTemplateMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<DeleteChecklistTemplateMutation, DeleteChecklistTemplateMutationVariables>(
    DeleteChecklistTemplateDocument,
    baseOptions,
  );
}
export type DeleteChecklistTemplateMutationHookResult = ReturnType<typeof useDeleteChecklistTemplateMutation>;
export type DeleteChecklistTemplateMutationResult = Apollo.MutationResult<DeleteChecklistTemplateMutation>;
export type DeleteChecklistTemplateMutationOptions = Apollo.BaseMutationOptions<
  DeleteChecklistTemplateMutation,
  DeleteChecklistTemplateMutationVariables
>;
export const DeleteLocalityDocument = gql`
  mutation deleteLocality($localityId: ID!) {
    deleteLocality(input: { id: $localityId }) {
      success
    }
  }
`;
export type DeleteLocalityMutationFn = Apollo.MutationFunction<DeleteLocalityMutation, DeleteLocalityMutationVariables>;

/**
 * __useDeleteLocalityMutation__
 *
 * To run a mutation, you first call `useDeleteLocalityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocalityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLocalityMutation, { data, loading, error }] = useDeleteLocalityMutation({
 *   variables: {
 *      localityId: // value for 'localityId'
 *   },
 * });
 */
export function useDeleteLocalityMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteLocalityMutation, DeleteLocalityMutationVariables>,
) {
  return ApolloReactHooks.useMutation<DeleteLocalityMutation, DeleteLocalityMutationVariables>(
    DeleteLocalityDocument,
    baseOptions,
  );
}
export type DeleteLocalityMutationHookResult = ReturnType<typeof useDeleteLocalityMutation>;
export type DeleteLocalityMutationResult = Apollo.MutationResult<DeleteLocalityMutation>;
export type DeleteLocalityMutationOptions = Apollo.BaseMutationOptions<
  DeleteLocalityMutation,
  DeleteLocalityMutationVariables
>;
export const DeleteTaskDocument = gql`
  mutation deleteTask($taskEventId: ID!, $scope: TaskDeletionScope!) {
    deleteTask(input: { taskEventId: $taskEventId, scope: $scope }) {
      success
    }
  }
`;
export type DeleteTaskMutationFn = Apollo.MutationFunction<DeleteTaskMutation, DeleteTaskMutationVariables>;

/**
 * __useDeleteTaskMutation__
 *
 * To run a mutation, you first call `useDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskMutation, { data, loading, error }] = useDeleteTaskMutation({
 *   variables: {
 *      taskEventId: // value for 'taskEventId'
 *      scope: // value for 'scope'
 *   },
 * });
 */
export function useDeleteTaskMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTaskMutation, DeleteTaskMutationVariables>,
) {
  return ApolloReactHooks.useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(DeleteTaskDocument, baseOptions);
}
export type DeleteTaskMutationHookResult = ReturnType<typeof useDeleteTaskMutation>;
export type DeleteTaskMutationResult = Apollo.MutationResult<DeleteTaskMutation>;
export type DeleteTaskMutationOptions = Apollo.BaseMutationOptions<DeleteTaskMutation, DeleteTaskMutationVariables>;
export const DeleteUserDeviceTokenDocument = gql`
  mutation deleteUserDeviceToken($token: String!) {
    deleteUserDeviceToken(input: { token: $token }) {
      errors {
        message
        field
      }
      success
    }
  }
`;
export type DeleteUserDeviceTokenMutationFn = Apollo.MutationFunction<
  DeleteUserDeviceTokenMutation,
  DeleteUserDeviceTokenMutationVariables
>;

/**
 * __useDeleteUserDeviceTokenMutation__
 *
 * To run a mutation, you first call `useDeleteUserDeviceTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserDeviceTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserDeviceTokenMutation, { data, loading, error }] = useDeleteUserDeviceTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useDeleteUserDeviceTokenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteUserDeviceTokenMutation,
    DeleteUserDeviceTokenMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<DeleteUserDeviceTokenMutation, DeleteUserDeviceTokenMutationVariables>(
    DeleteUserDeviceTokenDocument,
    baseOptions,
  );
}
export type DeleteUserDeviceTokenMutationHookResult = ReturnType<typeof useDeleteUserDeviceTokenMutation>;
export type DeleteUserDeviceTokenMutationResult = Apollo.MutationResult<DeleteUserDeviceTokenMutation>;
export type DeleteUserDeviceTokenMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserDeviceTokenMutation,
  DeleteUserDeviceTokenMutationVariables
>;
export const ExportTaskCsvDocument = gql`
  mutation exportTaskCsv($email: String!, $taskEventId: ID!) {
    exportTaskCsv(input: { email: $email, taskEventId: $taskEventId }) {
      errors {
        message
        field
      }
      success
    }
  }
`;
export type ExportTaskCsvMutationFn = Apollo.MutationFunction<ExportTaskCsvMutation, ExportTaskCsvMutationVariables>;

/**
 * __useExportTaskCsvMutation__
 *
 * To run a mutation, you first call `useExportTaskCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportTaskCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportTaskCsvMutation, { data, loading, error }] = useExportTaskCsvMutation({
 *   variables: {
 *      email: // value for 'email'
 *      taskEventId: // value for 'taskEventId'
 *   },
 * });
 */
export function useExportTaskCsvMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<ExportTaskCsvMutation, ExportTaskCsvMutationVariables>,
) {
  return ApolloReactHooks.useMutation<ExportTaskCsvMutation, ExportTaskCsvMutationVariables>(
    ExportTaskCsvDocument,
    baseOptions,
  );
}
export type ExportTaskCsvMutationHookResult = ReturnType<typeof useExportTaskCsvMutation>;
export type ExportTaskCsvMutationResult = Apollo.MutationResult<ExportTaskCsvMutation>;
export type ExportTaskCsvMutationOptions = Apollo.BaseMutationOptions<
  ExportTaskCsvMutation,
  ExportTaskCsvMutationVariables
>;
export const MarkTaskEventUnitsDocument = gql`
  mutation markTaskEventUnits($taskEventId: ID!, $doneIds: [ID!], $notDoneIds: [ID!]) {
    markTaskEventUnits(input: { taskEventId: $taskEventId, doneIds: $doneIds, notDoneIds: $notDoneIds }) {
      success
      errors {
        field
        message
      }
      taskEvent {
        ...TaskEventDetails
      }
    }
  }
  ${TaskEventDetailsFragmentDoc}
`;
export type MarkTaskEventUnitsMutationFn = Apollo.MutationFunction<
  MarkTaskEventUnitsMutation,
  MarkTaskEventUnitsMutationVariables
>;

/**
 * __useMarkTaskEventUnitsMutation__
 *
 * To run a mutation, you first call `useMarkTaskEventUnitsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkTaskEventUnitsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markTaskEventUnitsMutation, { data, loading, error }] = useMarkTaskEventUnitsMutation({
 *   variables: {
 *      taskEventId: // value for 'taskEventId'
 *      doneIds: // value for 'doneIds'
 *      notDoneIds: // value for 'notDoneIds'
 *   },
 * });
 */
export function useMarkTaskEventUnitsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<MarkTaskEventUnitsMutation, MarkTaskEventUnitsMutationVariables>,
) {
  return ApolloReactHooks.useMutation<MarkTaskEventUnitsMutation, MarkTaskEventUnitsMutationVariables>(
    MarkTaskEventUnitsDocument,
    baseOptions,
  );
}
export type MarkTaskEventUnitsMutationHookResult = ReturnType<typeof useMarkTaskEventUnitsMutation>;
export type MarkTaskEventUnitsMutationResult = Apollo.MutationResult<MarkTaskEventUnitsMutation>;
export type MarkTaskEventUnitsMutationOptions = Apollo.BaseMutationOptions<
  MarkTaskEventUnitsMutation,
  MarkTaskEventUnitsMutationVariables
>;
export const ReadTaskEventCommentsDocument = gql`
  mutation readTaskEventComments($taskEventId: ID!) {
    readTaskEventComments(input: { taskEventId: $taskEventId }) {
      success
      errors {
        field
        message
      }
      task {
        ...TaskEventDetails
      }
    }
  }
  ${TaskEventDetailsFragmentDoc}
`;
export type ReadTaskEventCommentsMutationFn = Apollo.MutationFunction<
  ReadTaskEventCommentsMutation,
  ReadTaskEventCommentsMutationVariables
>;

/**
 * __useReadTaskEventCommentsMutation__
 *
 * To run a mutation, you first call `useReadTaskEventCommentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadTaskEventCommentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readTaskEventCommentsMutation, { data, loading, error }] = useReadTaskEventCommentsMutation({
 *   variables: {
 *      taskEventId: // value for 'taskEventId'
 *   },
 * });
 */
export function useReadTaskEventCommentsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ReadTaskEventCommentsMutation,
    ReadTaskEventCommentsMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<ReadTaskEventCommentsMutation, ReadTaskEventCommentsMutationVariables>(
    ReadTaskEventCommentsDocument,
    baseOptions,
  );
}
export type ReadTaskEventCommentsMutationHookResult = ReturnType<typeof useReadTaskEventCommentsMutation>;
export type ReadTaskEventCommentsMutationResult = Apollo.MutationResult<ReadTaskEventCommentsMutation>;
export type ReadTaskEventCommentsMutationOptions = Apollo.BaseMutationOptions<
  ReadTaskEventCommentsMutation,
  ReadTaskEventCommentsMutationVariables
>;
export const RefreshTokenDocument = gql`
  mutation refreshToken($refresh_token: String!) {
    refreshToken(input: { refreshToken: $refresh_token }) {
      accessToken
      refreshToken
      errors {
        message
        field
      }
      success
    }
  }
`;
export type RefreshTokenMutationFn = Apollo.MutationFunction<RefreshTokenMutation, RefreshTokenMutationVariables>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *      refresh_token: // value for 'refresh_token'
 *   },
 * });
 */
export function useRefreshTokenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<RefreshTokenMutation, RefreshTokenMutationVariables>,
) {
  return ApolloReactHooks.useMutation<RefreshTokenMutation, RefreshTokenMutationVariables>(
    RefreshTokenDocument,
    baseOptions,
  );
}
export type RefreshTokenMutationHookResult = ReturnType<typeof useRefreshTokenMutation>;
export type RefreshTokenMutationResult = Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>;
export const SendChatNotificationsDocument = gql`
  mutation sendChatNotifications($message: String!, $dialogId: String!, $quickbloxUsersIds: [String!]!) {
    sendChatNotifications(input: { message: $message, dialogId: $dialogId, quickbloxUsersIds: $quickbloxUsersIds }) {
      errors {
        message
        field
      }
      success
    }
  }
`;
export type SendChatNotificationsMutationFn = Apollo.MutationFunction<
  SendChatNotificationsMutation,
  SendChatNotificationsMutationVariables
>;

/**
 * __useSendChatNotificationsMutation__
 *
 * To run a mutation, you first call `useSendChatNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendChatNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendChatNotificationsMutation, { data, loading, error }] = useSendChatNotificationsMutation({
 *   variables: {
 *      message: // value for 'message'
 *      dialogId: // value for 'dialogId'
 *      quickbloxUsersIds: // value for 'quickbloxUsersIds'
 *   },
 * });
 */
export function useSendChatNotificationsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendChatNotificationsMutation,
    SendChatNotificationsMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<SendChatNotificationsMutation, SendChatNotificationsMutationVariables>(
    SendChatNotificationsDocument,
    baseOptions,
  );
}
export type SendChatNotificationsMutationHookResult = ReturnType<typeof useSendChatNotificationsMutation>;
export type SendChatNotificationsMutationResult = Apollo.MutationResult<SendChatNotificationsMutation>;
export type SendChatNotificationsMutationOptions = Apollo.BaseMutationOptions<
  SendChatNotificationsMutation,
  SendChatNotificationsMutationVariables
>;
export const SendFeedbackDocument = gql`
  mutation sendFeedback($note: String!) {
    sendFeedback(input: { note: $note }) {
      errors {
        message
        field
      }
      success
    }
  }
`;
export type SendFeedbackMutationFn = Apollo.MutationFunction<SendFeedbackMutation, SendFeedbackMutationVariables>;

/**
 * __useSendFeedbackMutation__
 *
 * To run a mutation, you first call `useSendFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendFeedbackMutation, { data, loading, error }] = useSendFeedbackMutation({
 *   variables: {
 *      note: // value for 'note'
 *   },
 * });
 */
export function useSendFeedbackMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<SendFeedbackMutation, SendFeedbackMutationVariables>,
) {
  return ApolloReactHooks.useMutation<SendFeedbackMutation, SendFeedbackMutationVariables>(
    SendFeedbackDocument,
    baseOptions,
  );
}
export type SendFeedbackMutationHookResult = ReturnType<typeof useSendFeedbackMutation>;
export type SendFeedbackMutationResult = Apollo.MutationResult<SendFeedbackMutation>;
export type SendFeedbackMutationOptions = Apollo.BaseMutationOptions<
  SendFeedbackMutation,
  SendFeedbackMutationVariables
>;
export const SetChecklistTaskUpdateStatusDocument = gql`
  mutation setChecklistTaskUpdateStatus($id: ID!, $task_event_id: ID!, $status: ChecklistTaskUpdateStatus!) {
    setChecklistTaskUpdateStatus(input: { id: $id, taskEventId: $task_event_id, status: $status }) {
      checklistTask {
        id
        status
      }
      errors {
        message
        field
      }
      success
    }
  }
`;
export type SetChecklistTaskUpdateStatusMutationFn = Apollo.MutationFunction<
  SetChecklistTaskUpdateStatusMutation,
  SetChecklistTaskUpdateStatusMutationVariables
>;

/**
 * __useSetChecklistTaskUpdateStatusMutation__
 *
 * To run a mutation, you first call `useSetChecklistTaskUpdateStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetChecklistTaskUpdateStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setChecklistTaskUpdateStatusMutation, { data, loading, error }] = useSetChecklistTaskUpdateStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      task_event_id: // value for 'task_event_id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSetChecklistTaskUpdateStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetChecklistTaskUpdateStatusMutation,
    SetChecklistTaskUpdateStatusMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    SetChecklistTaskUpdateStatusMutation,
    SetChecklistTaskUpdateStatusMutationVariables
  >(SetChecklistTaskUpdateStatusDocument, baseOptions);
}
export type SetChecklistTaskUpdateStatusMutationHookResult = ReturnType<typeof useSetChecklistTaskUpdateStatusMutation>;
export type SetChecklistTaskUpdateStatusMutationResult = Apollo.MutationResult<SetChecklistTaskUpdateStatusMutation>;
export type SetChecklistTaskUpdateStatusMutationOptions = Apollo.BaseMutationOptions<
  SetChecklistTaskUpdateStatusMutation,
  SetChecklistTaskUpdateStatusMutationVariables
>;
export const SetTaskEventStatusDocument = gql`
  mutation setTaskEventStatus($taskEventId: ID!, $status: TaskStatus!) {
    setTaskEventStatus(input: { taskEventId: $taskEventId, status: $status }) {
      success
      errors {
        field
        message
      }
      task {
        ...TaskEventDetails
      }
    }
  }
  ${TaskEventDetailsFragmentDoc}
`;
export type SetTaskEventStatusMutationFn = Apollo.MutationFunction<
  SetTaskEventStatusMutation,
  SetTaskEventStatusMutationVariables
>;

/**
 * __useSetTaskEventStatusMutation__
 *
 * To run a mutation, you first call `useSetTaskEventStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetTaskEventStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setTaskEventStatusMutation, { data, loading, error }] = useSetTaskEventStatusMutation({
 *   variables: {
 *      taskEventId: // value for 'taskEventId'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSetTaskEventStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<SetTaskEventStatusMutation, SetTaskEventStatusMutationVariables>,
) {
  return ApolloReactHooks.useMutation<SetTaskEventStatusMutation, SetTaskEventStatusMutationVariables>(
    SetTaskEventStatusDocument,
    baseOptions,
  );
}
export type SetTaskEventStatusMutationHookResult = ReturnType<typeof useSetTaskEventStatusMutation>;
export type SetTaskEventStatusMutationResult = Apollo.MutationResult<SetTaskEventStatusMutation>;
export type SetTaskEventStatusMutationOptions = Apollo.BaseMutationOptions<
  SetTaskEventStatusMutation,
  SetTaskEventStatusMutationVariables
>;
export const SetUserNotificationsDocument = gql`
  mutation setUserNotifications($sendNotifications: Boolean!) {
    setUserNotifications(input: { sendNotifications: $sendNotifications }) {
      errors {
        message
        field
      }
      success
    }
  }
`;
export type SetUserNotificationsMutationFn = Apollo.MutationFunction<
  SetUserNotificationsMutation,
  SetUserNotificationsMutationVariables
>;

/**
 * __useSetUserNotificationsMutation__
 *
 * To run a mutation, you first call `useSetUserNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserNotificationsMutation, { data, loading, error }] = useSetUserNotificationsMutation({
 *   variables: {
 *      sendNotifications: // value for 'sendNotifications'
 *   },
 * });
 */
export function useSetUserNotificationsMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetUserNotificationsMutation,
    SetUserNotificationsMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<SetUserNotificationsMutation, SetUserNotificationsMutationVariables>(
    SetUserNotificationsDocument,
    baseOptions,
  );
}
export type SetUserNotificationsMutationHookResult = ReturnType<typeof useSetUserNotificationsMutation>;
export type SetUserNotificationsMutationResult = Apollo.MutationResult<SetUserNotificationsMutation>;
export type SetUserNotificationsMutationOptions = Apollo.BaseMutationOptions<
  SetUserNotificationsMutation,
  SetUserNotificationsMutationVariables
>;
export const SignOutDocument = gql`
  mutation signOut($refresh_token: String!) {
    signOut(input: { refreshToken: $refresh_token }) {
      errors {
        message
        field
      }
      success
    }
  }
`;
export type SignOutMutationFn = Apollo.MutationFunction<SignOutMutation, SignOutMutationVariables>;

/**
 * __useSignOutMutation__
 *
 * To run a mutation, you first call `useSignOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signOutMutation, { data, loading, error }] = useSignOutMutation({
 *   variables: {
 *      refresh_token: // value for 'refresh_token'
 *   },
 * });
 */
export function useSignOutMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<SignOutMutation, SignOutMutationVariables>,
) {
  return ApolloReactHooks.useMutation<SignOutMutation, SignOutMutationVariables>(SignOutDocument, baseOptions);
}
export type SignOutMutationHookResult = ReturnType<typeof useSignOutMutation>;
export type SignOutMutationResult = Apollo.MutationResult<SignOutMutation>;
export type SignOutMutationOptions = Apollo.BaseMutationOptions<SignOutMutation, SignOutMutationVariables>;
export const UpdateCommentDocument = gql`
  mutation updateComment($commentId: ID!, $value: String!) {
    updateComment(input: { id: $commentId, attributes: { value: $value } }) {
      success
      errors {
        field
        message
      }
      comment {
        ...TaskCommentItem
      }
    }
  }
  ${TaskCommentItemFragmentDoc}
`;
export type UpdateCommentMutationFn = Apollo.MutationFunction<UpdateCommentMutation, UpdateCommentMutationVariables>;

/**
 * __useUpdateCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentMutation, { data, loading, error }] = useUpdateCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateCommentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCommentMutation, UpdateCommentMutationVariables>,
) {
  return ApolloReactHooks.useMutation<UpdateCommentMutation, UpdateCommentMutationVariables>(
    UpdateCommentDocument,
    baseOptions,
  );
}
export type UpdateCommentMutationHookResult = ReturnType<typeof useUpdateCommentMutation>;
export type UpdateCommentMutationResult = Apollo.MutationResult<UpdateCommentMutation>;
export type UpdateCommentMutationOptions = Apollo.BaseMutationOptions<
  UpdateCommentMutation,
  UpdateCommentMutationVariables
>;
export const UpdateLocalityDocument = gql`
  mutation updateLocality(
    $localityId: ID!
    $name: String!
    $managerId: ID
    $grid: LocalityGrid!
    $aquatechnicianIds: [ID!]!
    $burs: [BursAttributes!]
    $feederIndex: Int!
  ) {
    updateLocality(
      input: {
        id: $localityId
        name: $name
        managerId: $managerId
        grid: $grid
        aquatechnicianIds: $aquatechnicianIds
        burs: $burs
        feederIndex: $feederIndex
      }
    ) {
      locality {
        ...LocalityDetails
      }
      errors {
        message
        field
      }
      success
    }
  }
  ${LocalityDetailsFragmentDoc}
`;
export type UpdateLocalityMutationFn = Apollo.MutationFunction<UpdateLocalityMutation, UpdateLocalityMutationVariables>;

/**
 * __useUpdateLocalityMutation__
 *
 * To run a mutation, you first call `useUpdateLocalityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocalityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocalityMutation, { data, loading, error }] = useUpdateLocalityMutation({
 *   variables: {
 *      localityId: // value for 'localityId'
 *      name: // value for 'name'
 *      managerId: // value for 'managerId'
 *      grid: // value for 'grid'
 *      aquatechnicianIds: // value for 'aquatechnicianIds'
 *      burs: // value for 'burs'
 *      feederIndex: // value for 'feederIndex'
 *   },
 * });
 */
export function useUpdateLocalityMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateLocalityMutation, UpdateLocalityMutationVariables>,
) {
  return ApolloReactHooks.useMutation<UpdateLocalityMutation, UpdateLocalityMutationVariables>(
    UpdateLocalityDocument,
    baseOptions,
  );
}
export type UpdateLocalityMutationHookResult = ReturnType<typeof useUpdateLocalityMutation>;
export type UpdateLocalityMutationResult = Apollo.MutationResult<UpdateLocalityMutation>;
export type UpdateLocalityMutationOptions = Apollo.BaseMutationOptions<
  UpdateLocalityMutation,
  UpdateLocalityMutationVariables
>;
export const UpdateMyStatusDocument = gql`
  mutation updateMyStatus($status: UserStatus!) {
    updateMyStatus(input: { status: $status }) {
      success
      me {
        ...UserDetails
      }
    }
  }
  ${UserDetailsFragmentDoc}
`;
export type UpdateMyStatusMutationFn = Apollo.MutationFunction<UpdateMyStatusMutation, UpdateMyStatusMutationVariables>;

/**
 * __useUpdateMyStatusMutation__
 *
 * To run a mutation, you first call `useUpdateMyStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyStatusMutation, { data, loading, error }] = useUpdateMyStatusMutation({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateMyStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMyStatusMutation, UpdateMyStatusMutationVariables>,
) {
  return ApolloReactHooks.useMutation<UpdateMyStatusMutation, UpdateMyStatusMutationVariables>(
    UpdateMyStatusDocument,
    baseOptions,
  );
}
export type UpdateMyStatusMutationHookResult = ReturnType<typeof useUpdateMyStatusMutation>;
export type UpdateMyStatusMutationResult = Apollo.MutationResult<UpdateMyStatusMutation>;
export type UpdateMyStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateMyStatusMutation,
  UpdateMyStatusMutationVariables
>;
export const UpdateTaskDocument = gql`
  mutation updateTask(
    $taskEventId: ID!
    $title: String!
    $description: String!
    $startAt: ISO8601Date!
    $lastOccurrenceAt: ISO8601Date!
    $localityId: ID!
    $categoryId: ID!
    $priority: TaskPriority!
    $recurrences: [RecurrenceAttributes!]!
    $checklistTasks: [ChecklistTaskAttributes!]
    $unitIds: [ID!]!
    $scope: TaskUpdateScope!
    $attachments: [DirectUploadAttributes!]
  ) {
    updateTask(
      input: {
        taskEventId: $taskEventId
        scope: $scope
        attributes: {
          title: $title
          description: $description
          startAt: $startAt
          lastOccurrenceAt: $lastOccurrenceAt
          localityId: $localityId
          categoryId: $categoryId
          priority: $priority
          recurrences: $recurrences
          checklistTasks: $checklistTasks
          unitIds: $unitIds
          attachments: $attachments
        }
      }
    ) {
      success
      errors {
        message
        field
      }
      task {
        ...TaskDetails
      }
    }
  }
  ${TaskDetailsFragmentDoc}
`;
export type UpdateTaskMutationFn = Apollo.MutationFunction<UpdateTaskMutation, UpdateTaskMutationVariables>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      taskEventId: // value for 'taskEventId'
 *      title: // value for 'title'
 *      description: // value for 'description'
 *      startAt: // value for 'startAt'
 *      lastOccurrenceAt: // value for 'lastOccurrenceAt'
 *      localityId: // value for 'localityId'
 *      categoryId: // value for 'categoryId'
 *      priority: // value for 'priority'
 *      recurrences: // value for 'recurrences'
 *      checklistTasks: // value for 'checklistTasks'
 *      unitIds: // value for 'unitIds'
 *      scope: // value for 'scope'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useUpdateTaskMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTaskMutation, UpdateTaskMutationVariables>,
) {
  return ApolloReactHooks.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(UpdateTaskDocument, baseOptions);
}
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = Apollo.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<UpdateTaskMutation, UpdateTaskMutationVariables>;
export const UpdateTaskEventDeadfishesDocument = gql`
  mutation updateTaskEventDeadfishes($taskEventId: ID!, $attributes: [UpdateDeadfishAttributes!]!) {
    updateTaskEventDeadfishes(input: { taskEventId: $taskEventId, attributes: $attributes }) {
      taskEvent {
        id
        details {
          deadfishes {
            id
            value
            category {
              id
              name
            }
          }
        }
      }
      errors {
        message
        field
      }
      success
    }
  }
`;
export type UpdateTaskEventDeadfishesMutationFn = Apollo.MutationFunction<
  UpdateTaskEventDeadfishesMutation,
  UpdateTaskEventDeadfishesMutationVariables
>;

/**
 * __useUpdateTaskEventDeadfishesMutation__
 *
 * To run a mutation, you first call `useUpdateTaskEventDeadfishesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskEventDeadfishesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskEventDeadfishesMutation, { data, loading, error }] = useUpdateTaskEventDeadfishesMutation({
 *   variables: {
 *      taskEventId: // value for 'taskEventId'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateTaskEventDeadfishesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateTaskEventDeadfishesMutation,
    UpdateTaskEventDeadfishesMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<UpdateTaskEventDeadfishesMutation, UpdateTaskEventDeadfishesMutationVariables>(
    UpdateTaskEventDeadfishesDocument,
    baseOptions,
  );
}
export type UpdateTaskEventDeadfishesMutationHookResult = ReturnType<typeof useUpdateTaskEventDeadfishesMutation>;
export type UpdateTaskEventDeadfishesMutationResult = Apollo.MutationResult<UpdateTaskEventDeadfishesMutation>;
export type UpdateTaskEventDeadfishesMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskEventDeadfishesMutation,
  UpdateTaskEventDeadfishesMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation updateUser(
    $id: ID!
    $clientMutationId: String
    $currentLocalityId: ID
    $email: String
    $firstName: String
    $lastName: String
    $name: String
    $phoneNumber: E164PhoneNumber
    $role: Role
  ) {
    updateUser(
      input: {
        id: $id
        attributes: {
          currentLocalityId: $currentLocalityId
          email: $email
          firstName: $firstName
          lastName: $lastName
          name: $name
          phoneNumber: $phoneNumber
          role: $role
        }
        clientMutationId: $clientMutationId
      }
    ) {
      user {
        ...UserDetails
      }
      errors {
        message
        field
      }
      success
    }
  }
  ${UserDetailsFragmentDoc}
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      clientMutationId: // value for 'clientMutationId'
 *      currentLocalityId: // value for 'currentLocalityId'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      name: // value for 'name'
 *      phoneNumber: // value for 'phoneNumber'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>,
) {
  return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const GetAllCategoriesDocument = gql`
  query getAllCategories {
    categories {
      nodes {
        ...CategoryItem
      }
    }
  }
  ${CategoryItemFragmentDoc}
`;

/**
 * __useGetAllCategoriesQuery__
 *
 * To run a query within a React component, call `useGetAllCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCategoriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>,
) {
  return ApolloReactHooks.useQuery<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>(
    GetAllCategoriesDocument,
    baseOptions,
  );
}
export function useGetAllCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>(
    GetAllCategoriesDocument,
    baseOptions,
  );
}
export type GetAllCategoriesQueryHookResult = ReturnType<typeof useGetAllCategoriesQuery>;
export type GetAllCategoriesLazyQueryHookResult = ReturnType<typeof useGetAllCategoriesLazyQuery>;
export type GetAllCategoriesQueryResult = Apollo.QueryResult<GetAllCategoriesQuery, GetAllCategoriesQueryVariables>;
export const GetCategoryDetailsDocument = gql`
  query getCategoryDetails($categoryId: ID!) {
    category(id: $categoryId) {
      ...CategoryItem
      checklistTasks {
        id
        order
        value
      }
    }
  }
  ${CategoryItemFragmentDoc}
`;

/**
 * __useGetCategoryDetailsQuery__
 *
 * To run a query within a React component, call `useGetCategoryDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryDetailsQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useGetCategoryDetailsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetCategoryDetailsQuery, GetCategoryDetailsQueryVariables>,
) {
  return ApolloReactHooks.useQuery<GetCategoryDetailsQuery, GetCategoryDetailsQueryVariables>(
    GetCategoryDetailsDocument,
    baseOptions,
  );
}
export function useGetCategoryDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCategoryDetailsQuery, GetCategoryDetailsQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<GetCategoryDetailsQuery, GetCategoryDetailsQueryVariables>(
    GetCategoryDetailsDocument,
    baseOptions,
  );
}
export type GetCategoryDetailsQueryHookResult = ReturnType<typeof useGetCategoryDetailsQuery>;
export type GetCategoryDetailsLazyQueryHookResult = ReturnType<typeof useGetCategoryDetailsLazyQuery>;
export type GetCategoryDetailsQueryResult = Apollo.QueryResult<
  GetCategoryDetailsQuery,
  GetCategoryDetailsQueryVariables
>;
export const GetDeadfishCategoriesDocument = gql`
  query getDeadfishCategories {
    deadfishCategories {
      nodes {
        id
        name
      }
    }
  }
`;

/**
 * __useGetDeadfishCategoriesQuery__
 *
 * To run a query within a React component, call `useGetDeadfishCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeadfishCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeadfishCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDeadfishCategoriesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetDeadfishCategoriesQuery, GetDeadfishCategoriesQueryVariables>,
) {
  return ApolloReactHooks.useQuery<GetDeadfishCategoriesQuery, GetDeadfishCategoriesQueryVariables>(
    GetDeadfishCategoriesDocument,
    baseOptions,
  );
}
export function useGetDeadfishCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDeadfishCategoriesQuery, GetDeadfishCategoriesQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<GetDeadfishCategoriesQuery, GetDeadfishCategoriesQueryVariables>(
    GetDeadfishCategoriesDocument,
    baseOptions,
  );
}
export type GetDeadfishCategoriesQueryHookResult = ReturnType<typeof useGetDeadfishCategoriesQuery>;
export type GetDeadfishCategoriesLazyQueryHookResult = ReturnType<typeof useGetDeadfishCategoriesLazyQuery>;
export type GetDeadfishCategoriesQueryResult = Apollo.QueryResult<
  GetDeadfishCategoriesQuery,
  GetDeadfishCategoriesQueryVariables
>;
export const ChecklistTemplateDocument = gql`
  query checklistTemplate($id: ID!) {
    checklistTemplate(id: $id) {
      id
      name
      checklistTemplateTasks {
        id
        value
      }
      canDestroy {
        value
      }
    }
  }
`;

/**
 * __useChecklistTemplateQuery__
 *
 * To run a query within a React component, call `useChecklistTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useChecklistTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChecklistTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChecklistTemplateQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ChecklistTemplateQuery, ChecklistTemplateQueryVariables>,
) {
  return ApolloReactHooks.useQuery<ChecklistTemplateQuery, ChecklistTemplateQueryVariables>(
    ChecklistTemplateDocument,
    baseOptions,
  );
}
export function useChecklistTemplateLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ChecklistTemplateQuery, ChecklistTemplateQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<ChecklistTemplateQuery, ChecklistTemplateQueryVariables>(
    ChecklistTemplateDocument,
    baseOptions,
  );
}
export type ChecklistTemplateQueryHookResult = ReturnType<typeof useChecklistTemplateQuery>;
export type ChecklistTemplateLazyQueryHookResult = ReturnType<typeof useChecklistTemplateLazyQuery>;
export type ChecklistTemplateQueryResult = Apollo.QueryResult<ChecklistTemplateQuery, ChecklistTemplateQueryVariables>;
export const ChecklistTemplatesDocument = gql`
  query checklistTemplates {
    checklistTemplates {
      edges {
        node {
          id
          name
          checklistTemplateTasks {
            id
            value
          }
          canDestroy {
            value
          }
        }
      }
    }
  }
`;

/**
 * __useChecklistTemplatesQuery__
 *
 * To run a query within a React component, call `useChecklistTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useChecklistTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChecklistTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useChecklistTemplatesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<ChecklistTemplatesQuery, ChecklistTemplatesQueryVariables>,
) {
  return ApolloReactHooks.useQuery<ChecklistTemplatesQuery, ChecklistTemplatesQueryVariables>(
    ChecklistTemplatesDocument,
    baseOptions,
  );
}
export function useChecklistTemplatesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ChecklistTemplatesQuery, ChecklistTemplatesQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<ChecklistTemplatesQuery, ChecklistTemplatesQueryVariables>(
    ChecklistTemplatesDocument,
    baseOptions,
  );
}
export type ChecklistTemplatesQueryHookResult = ReturnType<typeof useChecklistTemplatesQuery>;
export type ChecklistTemplatesLazyQueryHookResult = ReturnType<typeof useChecklistTemplatesLazyQuery>;
export type ChecklistTemplatesQueryResult = Apollo.QueryResult<
  ChecklistTemplatesQuery,
  ChecklistTemplatesQueryVariables
>;
export const GetAllLocalitiesDocument = gql`
  query getAllLocalities {
    localities {
      nodes {
        ...LocalityItem
      }
    }
  }
  ${LocalityItemFragmentDoc}
`;

/**
 * __useGetAllLocalitiesQuery__
 *
 * To run a query within a React component, call `useGetAllLocalitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllLocalitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllLocalitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllLocalitiesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllLocalitiesQuery, GetAllLocalitiesQueryVariables>,
) {
  return ApolloReactHooks.useQuery<GetAllLocalitiesQuery, GetAllLocalitiesQueryVariables>(
    GetAllLocalitiesDocument,
    baseOptions,
  );
}
export function useGetAllLocalitiesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllLocalitiesQuery, GetAllLocalitiesQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<GetAllLocalitiesQuery, GetAllLocalitiesQueryVariables>(
    GetAllLocalitiesDocument,
    baseOptions,
  );
}
export type GetAllLocalitiesQueryHookResult = ReturnType<typeof useGetAllLocalitiesQuery>;
export type GetAllLocalitiesLazyQueryHookResult = ReturnType<typeof useGetAllLocalitiesLazyQuery>;
export type GetAllLocalitiesQueryResult = Apollo.QueryResult<GetAllLocalitiesQuery, GetAllLocalitiesQueryVariables>;
export const GetLocalityDetailsDocument = gql`
  query getLocalityDetails($localityId: ID!) {
    locality(id: $localityId) {
      ...LocalityDetails
    }
  }
  ${LocalityDetailsFragmentDoc}
`;

/**
 * __useGetLocalityDetailsQuery__
 *
 * To run a query within a React component, call `useGetLocalityDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocalityDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocalityDetailsQuery({
 *   variables: {
 *      localityId: // value for 'localityId'
 *   },
 * });
 */
export function useGetLocalityDetailsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetLocalityDetailsQuery, GetLocalityDetailsQueryVariables>,
) {
  return ApolloReactHooks.useQuery<GetLocalityDetailsQuery, GetLocalityDetailsQueryVariables>(
    GetLocalityDetailsDocument,
    baseOptions,
  );
}
export function useGetLocalityDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLocalityDetailsQuery, GetLocalityDetailsQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<GetLocalityDetailsQuery, GetLocalityDetailsQueryVariables>(
    GetLocalityDetailsDocument,
    baseOptions,
  );
}
export type GetLocalityDetailsQueryHookResult = ReturnType<typeof useGetLocalityDetailsQuery>;
export type GetLocalityDetailsLazyQueryHookResult = ReturnType<typeof useGetLocalityDetailsLazyQuery>;
export type GetLocalityDetailsQueryResult = Apollo.QueryResult<
  GetLocalityDetailsQuery,
  GetLocalityDetailsQueryVariables
>;
export const GetMeDocument = gql`
  query GetMe {
    me {
      ...UserDetails
      canCreateTask {
        value
      }
      canCreateLocality {
        value
      }
      canCreateChecklistTemplate {
        value
      }
      permittedUserArguments {
        value
      }
    }
  }
  ${UserDetailsFragmentDoc}
`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
  return ApolloReactHooks.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, baseOptions);
}
export function useGetMeLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, baseOptions);
}
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const GetTasksEventsDetailsDocument = gql`
  query getTasksEventsDetails(
    $date: ISO8601DateTime
    $fromDate: ISO8601DateTime
    $toDate: ISO8601DateTime
    $localityId: ID
    $categoryId: ID
  ) {
    tasksEvents(onDate: $date, fromDate: $fromDate, toDate: $toDate, localityId: $localityId, categoryId: $categoryId) {
      nodes {
        ...TaskEventDetails
      }
    }
  }
  ${TaskEventDetailsFragmentDoc}
`;

/**
 * __useGetTasksEventsDetailsQuery__
 *
 * To run a query within a React component, call `useGetTasksEventsDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksEventsDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksEventsDetailsQuery({
 *   variables: {
 *      date: // value for 'date'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      localityId: // value for 'localityId'
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useGetTasksEventsDetailsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetTasksEventsDetailsQuery, GetTasksEventsDetailsQueryVariables>,
) {
  return ApolloReactHooks.useQuery<GetTasksEventsDetailsQuery, GetTasksEventsDetailsQueryVariables>(
    GetTasksEventsDetailsDocument,
    baseOptions,
  );
}
export function useGetTasksEventsDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTasksEventsDetailsQuery, GetTasksEventsDetailsQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<GetTasksEventsDetailsQuery, GetTasksEventsDetailsQueryVariables>(
    GetTasksEventsDetailsDocument,
    baseOptions,
  );
}
export type GetTasksEventsDetailsQueryHookResult = ReturnType<typeof useGetTasksEventsDetailsQuery>;
export type GetTasksEventsDetailsLazyQueryHookResult = ReturnType<typeof useGetTasksEventsDetailsLazyQuery>;
export type GetTasksEventsDetailsQueryResult = Apollo.QueryResult<
  GetTasksEventsDetailsQuery,
  GetTasksEventsDetailsQueryVariables
>;
export const GetTaskEventDetailsDocument = gql`
  query getTaskEventDetails($taskEventId: ID!) {
    taskEvent(id: $taskEventId) {
      ...TaskEventDetails
    }
  }
  ${TaskEventDetailsFragmentDoc}
`;

/**
 * __useGetTaskEventDetailsQuery__
 *
 * To run a query within a React component, call `useGetTaskEventDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskEventDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskEventDetailsQuery({
 *   variables: {
 *      taskEventId: // value for 'taskEventId'
 *   },
 * });
 */
export function useGetTaskEventDetailsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaskEventDetailsQuery, GetTaskEventDetailsQueryVariables>,
) {
  return ApolloReactHooks.useQuery<GetTaskEventDetailsQuery, GetTaskEventDetailsQueryVariables>(
    GetTaskEventDetailsDocument,
    baseOptions,
  );
}
export function useGetTaskEventDetailsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaskEventDetailsQuery, GetTaskEventDetailsQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<GetTaskEventDetailsQuery, GetTaskEventDetailsQueryVariables>(
    GetTaskEventDetailsDocument,
    baseOptions,
  );
}
export type GetTaskEventDetailsQueryHookResult = ReturnType<typeof useGetTaskEventDetailsQuery>;
export type GetTaskEventDetailsLazyQueryHookResult = ReturnType<typeof useGetTaskEventDetailsLazyQuery>;
export type GetTaskEventDetailsQueryResult = Apollo.QueryResult<
  GetTaskEventDetailsQuery,
  GetTaskEventDetailsQueryVariables
>;
export const GetUserDocument = gql`
  query getUser($id: ID!) {
    user(id: $id) {
      ...UserItem
      localities {
        id
        name
      }
    }
  }
  ${UserItemFragmentDoc}
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
  return ApolloReactHooks.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
}
export function useGetUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUsersDocument = gql`
  query getUsers($name: String, $roles: [Role!], $currentLocalityIds: [ID!], $localityIds: [ID!]) {
    users(name: $name, roles: $roles, currentLocalityIds: $currentLocalityIds, localityIds: $localityIds) {
      nodes {
        ...UserItem
      }
    }
  }
  ${UserItemFragmentDoc}
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      name: // value for 'name'
 *      roles: // value for 'roles'
 *      currentLocalityIds: // value for 'currentLocalityIds'
 *      localityIds: // value for 'localityIds'
 *   },
 * });
 */
export function useGetUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>,
) {
  return ApolloReactHooks.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, baseOptions);
}
export function useGetUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, baseOptions);
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetPaginatedUsersDocument = gql`
  query getPaginatedUsers(
    $after: String
    $first: Int
    $name: String
    $roles: [Role!]
    $currentLocalityIds: [ID!]
    $localityIds: [ID!]
  ) {
    users(
      name: $name
      roles: $roles
      currentLocalityIds: $currentLocalityIds
      localityIds: $localityIds
      first: $first
      after: $after
    ) {
      edges {
        cursor
        node {
          ...UserItem
        }
      }
      pageInfo {
        ...StartToEndPageInfo
      }
    }
  }
  ${UserItemFragmentDoc}
  ${StartToEndPageInfoFragmentDoc}
`;

/**
 * __useGetPaginatedUsersQuery__
 *
 * To run a query within a React component, call `useGetPaginatedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaginatedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaginatedUsersQuery({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      name: // value for 'name'
 *      roles: // value for 'roles'
 *      currentLocalityIds: // value for 'currentLocalityIds'
 *      localityIds: // value for 'localityIds'
 *   },
 * });
 */
export function useGetPaginatedUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetPaginatedUsersQuery, GetPaginatedUsersQueryVariables>,
) {
  return ApolloReactHooks.useQuery<GetPaginatedUsersQuery, GetPaginatedUsersQueryVariables>(
    GetPaginatedUsersDocument,
    baseOptions,
  );
}
export function useGetPaginatedUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPaginatedUsersQuery, GetPaginatedUsersQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<GetPaginatedUsersQuery, GetPaginatedUsersQueryVariables>(
    GetPaginatedUsersDocument,
    baseOptions,
  );
}
export type GetPaginatedUsersQueryHookResult = ReturnType<typeof useGetPaginatedUsersQuery>;
export type GetPaginatedUsersLazyQueryHookResult = ReturnType<typeof useGetPaginatedUsersLazyQuery>;
export type GetPaginatedUsersQueryResult = Apollo.QueryResult<GetPaginatedUsersQuery, GetPaginatedUsersQueryVariables>;
export const PresignUploadDocument = gql`
  query presignUpload($fileName: String!, $fileType: String!, $objectType: PresignObject!) {
    presignUpload(fileName: $fileName, fileType: $fileType, objectType: $objectType) {
      fields
      url
    }
  }
`;

/**
 * __usePresignUploadQuery__
 *
 * To run a query within a React component, call `usePresignUploadQuery` and pass it any options that fit your needs.
 * When your component renders, `usePresignUploadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePresignUploadQuery({
 *   variables: {
 *      fileName: // value for 'fileName'
 *      fileType: // value for 'fileType'
 *      objectType: // value for 'objectType'
 *   },
 * });
 */
export function usePresignUploadQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<PresignUploadQuery, PresignUploadQueryVariables>,
) {
  return ApolloReactHooks.useQuery<PresignUploadQuery, PresignUploadQueryVariables>(PresignUploadDocument, baseOptions);
}
export function usePresignUploadLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PresignUploadQuery, PresignUploadQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<PresignUploadQuery, PresignUploadQueryVariables>(
    PresignUploadDocument,
    baseOptions,
  );
}
export type PresignUploadQueryHookResult = ReturnType<typeof usePresignUploadQuery>;
export type PresignUploadLazyQueryHookResult = ReturnType<typeof usePresignUploadLazyQuery>;
export type PresignUploadQueryResult = Apollo.QueryResult<PresignUploadQuery, PresignUploadQueryVariables>;
export const QuickbloxSessionTokenDocument = gql`
  query quickbloxSessionToken {
    quickbloxSessionToken {
      token
    }
  }
`;

/**
 * __useQuickbloxSessionTokenQuery__
 *
 * To run a query within a React component, call `useQuickbloxSessionTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickbloxSessionTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickbloxSessionTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useQuickbloxSessionTokenQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<QuickbloxSessionTokenQuery, QuickbloxSessionTokenQueryVariables>,
) {
  return ApolloReactHooks.useQuery<QuickbloxSessionTokenQuery, QuickbloxSessionTokenQueryVariables>(
    QuickbloxSessionTokenDocument,
    baseOptions,
  );
}
export function useQuickbloxSessionTokenLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QuickbloxSessionTokenQuery, QuickbloxSessionTokenQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<QuickbloxSessionTokenQuery, QuickbloxSessionTokenQueryVariables>(
    QuickbloxSessionTokenDocument,
    baseOptions,
  );
}
export type QuickbloxSessionTokenQueryHookResult = ReturnType<typeof useQuickbloxSessionTokenQuery>;
export type QuickbloxSessionTokenLazyQueryHookResult = ReturnType<typeof useQuickbloxSessionTokenLazyQuery>;
export type QuickbloxSessionTokenQueryResult = Apollo.QueryResult<
  QuickbloxSessionTokenQuery,
  QuickbloxSessionTokenQueryVariables
>;
export const GetTaskEventCommentsDocument = gql`
  query getTaskEventComments($taskEventId: ID!, $first: Int, $after: String) {
    taskEventComments(taskEventId: $taskEventId, first: $first, after: $after) {
      edges {
        node {
          ...TaskCommentItem
        }
      }
      pageInfo {
        ...StartToEndPageInfo
      }
    }
  }
  ${TaskCommentItemFragmentDoc}
  ${StartToEndPageInfoFragmentDoc}
`;

/**
 * __useGetTaskEventCommentsQuery__
 *
 * To run a query within a React component, call `useGetTaskEventCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskEventCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskEventCommentsQuery({
 *   variables: {
 *      taskEventId: // value for 'taskEventId'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useGetTaskEventCommentsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaskEventCommentsQuery, GetTaskEventCommentsQueryVariables>,
) {
  return ApolloReactHooks.useQuery<GetTaskEventCommentsQuery, GetTaskEventCommentsQueryVariables>(
    GetTaskEventCommentsDocument,
    baseOptions,
  );
}
export function useGetTaskEventCommentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaskEventCommentsQuery, GetTaskEventCommentsQueryVariables>,
) {
  return ApolloReactHooks.useLazyQuery<GetTaskEventCommentsQuery, GetTaskEventCommentsQueryVariables>(
    GetTaskEventCommentsDocument,
    baseOptions,
  );
}
export type GetTaskEventCommentsQueryHookResult = ReturnType<typeof useGetTaskEventCommentsQuery>;
export type GetTaskEventCommentsLazyQueryHookResult = ReturnType<typeof useGetTaskEventCommentsLazyQuery>;
export type GetTaskEventCommentsQueryResult = Apollo.QueryResult<
  GetTaskEventCommentsQuery,
  GetTaskEventCommentsQueryVariables
>;
export const CreateCommentDocument = gql`
  mutation createComment($taskEventId: ID!, $value: String!, $attachments: [DirectUploadAttributes!]) {
    createComment(input: { taskEventId: $taskEventId, attributes: { value: $value, attachments: $attachments } }) {
      success
      comment {
        ...TaskCommentItem
      }
    }
  }
  ${TaskCommentItemFragmentDoc}
`;
export type CreateCommentMutationFn = Apollo.MutationFunction<CreateCommentMutation, CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      taskEventId: // value for 'taskEventId'
 *      value: // value for 'value'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useCreateCommentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCommentMutation, CreateCommentMutationVariables>,
) {
  return ApolloReactHooks.useMutation<CreateCommentMutation, CreateCommentMutationVariables>(
    CreateCommentDocument,
    baseOptions,
  );
}
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<
  CreateCommentMutation,
  CreateCommentMutationVariables
>;
export const MarkCommentDocument = gql`
  mutation markComment($commentId: ID!, $starred: Boolean!) {
    markComment(input: { id: $commentId, starred: $starred }) {
      success
      comment {
        ...TaskCommentItem
      }
    }
  }
  ${TaskCommentItemFragmentDoc}
`;
export type MarkCommentMutationFn = Apollo.MutationFunction<MarkCommentMutation, MarkCommentMutationVariables>;

/**
 * __useMarkCommentMutation__
 *
 * To run a mutation, you first call `useMarkCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markCommentMutation, { data, loading, error }] = useMarkCommentMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *      starred: // value for 'starred'
 *   },
 * });
 */
export function useMarkCommentMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<MarkCommentMutation, MarkCommentMutationVariables>,
) {
  return ApolloReactHooks.useMutation<MarkCommentMutation, MarkCommentMutationVariables>(
    MarkCommentDocument,
    baseOptions,
  );
}
export type MarkCommentMutationHookResult = ReturnType<typeof useMarkCommentMutation>;
export type MarkCommentMutationResult = Apollo.MutationResult<MarkCommentMutation>;
export type MarkCommentMutationOptions = Apollo.BaseMutationOptions<MarkCommentMutation, MarkCommentMutationVariables>;
export const WatchChecklistTaskStatusUpdatedDocument = gql`
  subscription watchChecklistTaskStatusUpdated($taskEventId: ID!) {
    checklistTaskStatusUpdated(taskEventId: $taskEventId) {
      checklistTask {
        id
        status
        value
      }
    }
  }
`;

/**
 * __useWatchChecklistTaskStatusUpdatedSubscription__
 *
 * To run a query within a React component, call `useWatchChecklistTaskStatusUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchChecklistTaskStatusUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchChecklistTaskStatusUpdatedSubscription({
 *   variables: {
 *      taskEventId: // value for 'taskEventId'
 *   },
 * });
 */
export function useWatchChecklistTaskStatusUpdatedSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    WatchChecklistTaskStatusUpdatedSubscription,
    WatchChecklistTaskStatusUpdatedSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    WatchChecklistTaskStatusUpdatedSubscription,
    WatchChecklistTaskStatusUpdatedSubscriptionVariables
  >(WatchChecklistTaskStatusUpdatedDocument, baseOptions);
}
export type WatchChecklistTaskStatusUpdatedSubscriptionHookResult = ReturnType<
  typeof useWatchChecklistTaskStatusUpdatedSubscription
>;
export type WatchChecklistTaskStatusUpdatedSubscriptionResult = Apollo.SubscriptionResult<
  WatchChecklistTaskStatusUpdatedSubscription
>;
export const WatchTaskCommentCreatedDocument = gql`
  subscription watchTaskCommentCreated($taskEventId: ID!) {
    taskCommentCreated(taskEventId: $taskEventId) {
      comment {
        ...TaskCommentItem
      }
    }
  }
  ${TaskCommentItemFragmentDoc}
`;

/**
 * __useWatchTaskCommentCreatedSubscription__
 *
 * To run a query within a React component, call `useWatchTaskCommentCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchTaskCommentCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchTaskCommentCreatedSubscription({
 *   variables: {
 *      taskEventId: // value for 'taskEventId'
 *   },
 * });
 */
export function useWatchTaskCommentCreatedSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    WatchTaskCommentCreatedSubscription,
    WatchTaskCommentCreatedSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    WatchTaskCommentCreatedSubscription,
    WatchTaskCommentCreatedSubscriptionVariables
  >(WatchTaskCommentCreatedDocument, baseOptions);
}
export type WatchTaskCommentCreatedSubscriptionHookResult = ReturnType<typeof useWatchTaskCommentCreatedSubscription>;
export type WatchTaskCommentCreatedSubscriptionResult = Apollo.SubscriptionResult<WatchTaskCommentCreatedSubscription>;
export const WatchTaskCommentStarredDocument = gql`
  subscription watchTaskCommentStarred($taskEventId: ID!) {
    taskCommentStarred(taskEventId: $taskEventId) {
      comment {
        ...TaskCommentItem
      }
    }
  }
  ${TaskCommentItemFragmentDoc}
`;

/**
 * __useWatchTaskCommentStarredSubscription__
 *
 * To run a query within a React component, call `useWatchTaskCommentStarredSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchTaskCommentStarredSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchTaskCommentStarredSubscription({
 *   variables: {
 *      taskEventId: // value for 'taskEventId'
 *   },
 * });
 */
export function useWatchTaskCommentStarredSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    WatchTaskCommentStarredSubscription,
    WatchTaskCommentStarredSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    WatchTaskCommentStarredSubscription,
    WatchTaskCommentStarredSubscriptionVariables
  >(WatchTaskCommentStarredDocument, baseOptions);
}
export type WatchTaskCommentStarredSubscriptionHookResult = ReturnType<typeof useWatchTaskCommentStarredSubscription>;
export type WatchTaskCommentStarredSubscriptionResult = Apollo.SubscriptionResult<WatchTaskCommentStarredSubscription>;
export const WatchTaskCommentUnstarredDocument = gql`
  subscription watchTaskCommentUnstarred($taskEventId: ID!) {
    taskCommentUnstarred(taskEventId: $taskEventId) {
      comment {
        ...TaskCommentItem
      }
    }
  }
  ${TaskCommentItemFragmentDoc}
`;

/**
 * __useWatchTaskCommentUnstarredSubscription__
 *
 * To run a query within a React component, call `useWatchTaskCommentUnstarredSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchTaskCommentUnstarredSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchTaskCommentUnstarredSubscription({
 *   variables: {
 *      taskEventId: // value for 'taskEventId'
 *   },
 * });
 */
export function useWatchTaskCommentUnstarredSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    WatchTaskCommentUnstarredSubscription,
    WatchTaskCommentUnstarredSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    WatchTaskCommentUnstarredSubscription,
    WatchTaskCommentUnstarredSubscriptionVariables
  >(WatchTaskCommentUnstarredDocument, baseOptions);
}
export type WatchTaskCommentUnstarredSubscriptionHookResult = ReturnType<
  typeof useWatchTaskCommentUnstarredSubscription
>;
export type WatchTaskCommentUnstarredSubscriptionResult = Apollo.SubscriptionResult<
  WatchTaskCommentUnstarredSubscription
>;
export const WatchTaskCommentUpdatedDocument = gql`
  subscription watchTaskCommentUpdated($taskEventId: ID!) {
    taskCommentUpdated(taskEventId: $taskEventId) {
      comment {
        ...TaskCommentItem
      }
    }
  }
  ${TaskCommentItemFragmentDoc}
`;

/**
 * __useWatchTaskCommentUpdatedSubscription__
 *
 * To run a query within a React component, call `useWatchTaskCommentUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchTaskCommentUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchTaskCommentUpdatedSubscription({
 *   variables: {
 *      taskEventId: // value for 'taskEventId'
 *   },
 * });
 */
export function useWatchTaskCommentUpdatedSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    WatchTaskCommentUpdatedSubscription,
    WatchTaskCommentUpdatedSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    WatchTaskCommentUpdatedSubscription,
    WatchTaskCommentUpdatedSubscriptionVariables
  >(WatchTaskCommentUpdatedDocument, baseOptions);
}
export type WatchTaskCommentUpdatedSubscriptionHookResult = ReturnType<typeof useWatchTaskCommentUpdatedSubscription>;
export type WatchTaskCommentUpdatedSubscriptionResult = Apollo.SubscriptionResult<WatchTaskCommentUpdatedSubscription>;
export const WatchTaskEventCreatedDocument = gql`
  subscription watchTaskEventCreated($localityId: ID!) {
    taskEventCreated(localityId: $localityId) {
      tasks {
        ...TaskEventDetails
      }
    }
  }
  ${TaskEventDetailsFragmentDoc}
`;

/**
 * __useWatchTaskEventCreatedSubscription__
 *
 * To run a query within a React component, call `useWatchTaskEventCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchTaskEventCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchTaskEventCreatedSubscription({
 *   variables: {
 *      localityId: // value for 'localityId'
 *   },
 * });
 */
export function useWatchTaskEventCreatedSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    WatchTaskEventCreatedSubscription,
    WatchTaskEventCreatedSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    WatchTaskEventCreatedSubscription,
    WatchTaskEventCreatedSubscriptionVariables
  >(WatchTaskEventCreatedDocument, baseOptions);
}
export type WatchTaskEventCreatedSubscriptionHookResult = ReturnType<typeof useWatchTaskEventCreatedSubscription>;
export type WatchTaskEventCreatedSubscriptionResult = Apollo.SubscriptionResult<WatchTaskEventCreatedSubscription>;
export const WatchTaskEventDeletedDocument = gql`
  subscription watchTaskEventDeleted($localityId: ID!) {
    taskEventDeleted(localityId: $localityId) {
      tasks {
        ...TaskEventDetails
      }
    }
  }
  ${TaskEventDetailsFragmentDoc}
`;

/**
 * __useWatchTaskEventDeletedSubscription__
 *
 * To run a query within a React component, call `useWatchTaskEventDeletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchTaskEventDeletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchTaskEventDeletedSubscription({
 *   variables: {
 *      localityId: // value for 'localityId'
 *   },
 * });
 */
export function useWatchTaskEventDeletedSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    WatchTaskEventDeletedSubscription,
    WatchTaskEventDeletedSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    WatchTaskEventDeletedSubscription,
    WatchTaskEventDeletedSubscriptionVariables
  >(WatchTaskEventDeletedDocument, baseOptions);
}
export type WatchTaskEventDeletedSubscriptionHookResult = ReturnType<typeof useWatchTaskEventDeletedSubscription>;
export type WatchTaskEventDeletedSubscriptionResult = Apollo.SubscriptionResult<WatchTaskEventDeletedSubscription>;
export const WatchTaskEventUpdatedDocument = gql`
  subscription watchTaskEventUpdated($localityId: ID!) {
    taskEventUpdated(localityId: $localityId) {
      tasks {
        ...TaskEventDetails
      }
    }
  }
  ${TaskEventDetailsFragmentDoc}
`;

/**
 * __useWatchTaskEventUpdatedSubscription__
 *
 * To run a query within a React component, call `useWatchTaskEventUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchTaskEventUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchTaskEventUpdatedSubscription({
 *   variables: {
 *      localityId: // value for 'localityId'
 *   },
 * });
 */
export function useWatchTaskEventUpdatedSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<
    WatchTaskEventUpdatedSubscription,
    WatchTaskEventUpdatedSubscriptionVariables
  >,
) {
  return ApolloReactHooks.useSubscription<
    WatchTaskEventUpdatedSubscription,
    WatchTaskEventUpdatedSubscriptionVariables
  >(WatchTaskEventUpdatedDocument, baseOptions);
}
export type WatchTaskEventUpdatedSubscriptionHookResult = ReturnType<typeof useWatchTaskEventUpdatedSubscription>;
export type WatchTaskEventUpdatedSubscriptionResult = Apollo.SubscriptionResult<WatchTaskEventUpdatedSubscription>;
export const WatchUsersDocument = gql`
  subscription watchUsers {
    userStatusUpdated {
      user {
        id
        status
      }
    }
  }
`;

/**
 * __useWatchUsersSubscription__
 *
 * To run a query within a React component, call `useWatchUsersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchUsersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchUsersSubscription({
 *   variables: {
 *   },
 * });
 */
export function useWatchUsersSubscription(
  baseOptions?: ApolloReactHooks.SubscriptionHookOptions<WatchUsersSubscription, WatchUsersSubscriptionVariables>,
) {
  return ApolloReactHooks.useSubscription<WatchUsersSubscription, WatchUsersSubscriptionVariables>(
    WatchUsersDocument,
    baseOptions,
  );
}
export type WatchUsersSubscriptionHookResult = ReturnType<typeof useWatchUsersSubscription>;
export type WatchUsersSubscriptionResult = Apollo.SubscriptionResult<WatchUsersSubscription>;
