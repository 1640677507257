import { decode } from 'jsonwebtoken';

export function isAccessTokenExpired(timeOffsetMs = 0): boolean {
  const accessToken = localStorage.getItem('accessToken');
  if (!accessToken) return true;
  const decoded = decode(accessToken);

  if (!decoded) return true;
  if (typeof decoded === 'string') return true;
  if (!('exp' in decoded)) return true;

  const expire = parseInt(decoded.exp, 10) * 1000;
  const now = Date.now();

  return expire - now < timeOffsetMs;
}
