import { LocalityGrid } from 'generated/graphql';

import { getGridRowsAndCols } from './getGridRowsAndCols';

const getSideOffset = (arrangement: LocalityGrid): number => {
  switch (arrangement) {
    case LocalityGrid['2X6']:
    case LocalityGrid['2X7']:
    case LocalityGrid['2X8']:
      return -1 / 9;
    case LocalityGrid['3X5']:
    case LocalityGrid['3X6']:
    case LocalityGrid['3X8']:
      return 0;
  }
};

export function getBargePosition(
  bargeIndex: number,
  arrangement: LocalityGrid
): { top: number; left: number; isVertical: boolean } {
  const { rows, cols } = getGridRowsAndCols(arrangement);

  const maxIndex = 2 * (cols + rows);

  // safe index
  const index = bargeIndex % maxIndex;

  const isTop = index + 1 < cols;
  const isRight = index < cols + rows;
  const isBottom = index + 1 < 2 * cols + rows;

  if (isTop) {
    const A = 1 / cols;
    const B = 1 / cols;

    return {
      left: A * index + B,
      top: 0,
      isVertical: false,
    };
  } else if (isRight) {
    const A = 1 / rows;
    const B = (1 - cols) / rows;
    const sideOffset = getSideOffset(arrangement);

    return {
      left: 1 - sideOffset,
      top: A * index + B,
      isVertical: true,
    };
  } else if (isBottom) {
    const A = -1 / cols;
    const B = (2 * cols + rows - 1) / cols;

    return {
      left: A * index + B,
      top: 1,
      isVertical: false,
    };
  } else {
    const A = -1 / rows;
    const B = (2 * (cols + rows) - 1) / rows;
    const sideOffset = getSideOffset(arrangement);

    return {
      left: sideOffset,
      top: A * index + B,
      isVertical: true,
    };
  }
}
