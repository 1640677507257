import { useEffect } from 'react';

import ReactGA from 'react-ga';
import { useHistory } from 'react-router-dom';

import { useGetMeQuery } from 'generated/graphql';
import { useOnlineStatus } from 'hooks/useOnlineStatus';

const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

export const ga = (): void => {
  if (!trackingId) return;
  ReactGA.initialize(trackingId);
};

export const useGoogleAnalytics = (): void => {
  const history = useHistory();
  const isOnline = useOnlineStatus();
  const { data } = useGetMeQuery();

  const userId = data?.me?.id;

  useEffect(() => {
    if (!trackingId) return;
    if (userId) {
      ReactGA.set({ userId });
    }
  }, [userId]);

  const userRole = data?.me?.role;
  useEffect(() => {
    if (!trackingId) return;
    if (userRole) {
      ReactGA.set({ userRole });
    }
  }, [userRole]);

  useEffect(() => {
    if (!trackingId) return;
    const networkStatus = isOnline ? 'online' : 'offline';
    ReactGA.set({ networkStatus });
  }, [isOnline]);

  useEffect(() => {
    if (!trackingId) return;
    return history.listen((location) => {
      ReactGA.set({ page: location.pathname }); // Update the user's current page
      ReactGA.pageview(location.pathname); // Record a pageview for the given page
    });
  }, []);
};
