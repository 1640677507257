import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { endOfWeek, startOfWeek } from 'config/dateFns';
import { TaskPriority } from 'generated/graphql';
import { RootState } from 'state/store';

import {
  DateFilter,
  DateFilterState,
  DateFilterTypes,
  TasksFiltersState,
} from './interfaces';

const getInitialState = (): TasksFiltersState => {
  const localStorageFilters = localStorage.getItem('filters') || undefined;
  if (localStorageFilters) {
    return JSON.parse(localStorageFilters);
  }

  const today = new Date();
  return {
    date: {
      type: DateFilterTypes.Range,
      value: {
        start: startOfWeek(today).getTime(),
        end: endOfWeek(today).getTime(),
      },
    },
  };
};

const saveFiltersToLocalStorage = (data: TasksFiltersState) => {
  localStorage.setItem('filters', JSON.stringify(data));
};

// slice
const tasksFiltersSlice = createSlice({
  name: 'tasksFilters',
  initialState: getInitialState(),
  reducers: {
    setDateFilterState: (
      state,
      { payload }: PayloadAction<DateFilterState>
    ) => {
      const data = { ...state, date: payload };
      saveFiltersToLocalStorage(data);
      return data;
    },
    setLocalityFilter: (
      state,
      { payload }: PayloadAction<string | undefined>
    ) => {
      const data = { ...state, localityId: payload };
      saveFiltersToLocalStorage(data);
      return data;
    },
    setPrioritiesFilter: (
      state,
      { payload }: PayloadAction<TaskPriority[] | undefined>
    ) => {
      const data = { ...state, priorities: payload };
      saveFiltersToLocalStorage(data);
      return data;
    },
    setCategoryFilter: (
      state,
      { payload }: PayloadAction<string[] | undefined>
    ) => {
      const data = { ...state, categories: payload };
      saveFiltersToLocalStorage(data);
      return data;
    },
    setUnitsFilter: (
      state,
      { payload }: PayloadAction<string[] | undefined>
    ) => {
      const data = { ...state, unitsIds: payload };
      saveFiltersToLocalStorage(data);
      return data;
    },
    toggleUnitFilter: (state, { payload }: PayloadAction<string>) => {
      const currentFilter = state.unitsIds;
      let data;

      if (!currentFilter) {
        data = { ...state, unitsIds: [payload] };
        saveFiltersToLocalStorage(data);
        return data;
      }

      if (currentFilter.includes(payload)) {
        data = {
          ...state,
          unitsIds: currentFilter.filter((unitId) => unitId !== payload),
        };
      } else {
        data = { ...state, unitsIds: [...currentFilter, payload] };
      }
      saveFiltersToLocalStorage(data);
      return data;
    },
  },
});

// reducer
export default tasksFiltersSlice.reducer;

// actions
export const {
  setDateFilterState,
  setLocalityFilter,
  setPrioritiesFilter,
  setCategoryFilter,
  setUnitsFilter,
  toggleUnitFilter,
} = tasksFiltersSlice.actions;

// selectors
export const dateFilterSelector = (state: RootState): DateFilter => {
  const dateFilter = state.tasksFilters.date;
  switch (dateFilter.type) {
    case DateFilterTypes.Single: {
      return {
        type: dateFilter.type,
        value: new Date(dateFilter.value),
      };
    }
    case DateFilterTypes.Range: {
      return {
        type: dateFilter.type,
        value: {
          start: new Date(dateFilter.value.start),
          end: new Date(dateFilter.value.end),
        },
      };
    }
  }
};

export const localityFilterSelector = (
  state: RootState
): TasksFiltersState['localityId'] => state.tasksFilters.localityId;

export const prioritiesFilterSelector = (
  state: RootState
): TasksFiltersState['priorities'] => state.tasksFilters.priorities;

export const categoryFilterSelector = (
  state: RootState
): TasksFiltersState['categories'] => state.tasksFilters.categories;

export const unitsFilterSelector = (
  state: RootState
): TasksFiltersState['unitsIds'] => state.tasksFilters.unitsIds;

export const filtersSelector = (state: RootState): TasksFiltersState =>
  state.tasksFilters;

// action creators
export const setDateFilter = (
  dateFilter: DateFilter
): ReturnType<typeof setDateFilterState> => {
  switch (dateFilter.type) {
    case DateFilterTypes.Single: {
      return setDateFilterState({
        type: dateFilter.type,
        value: dateFilter.value.getTime(),
      });
    }
    case DateFilterTypes.Range: {
      return setDateFilterState({
        type: dateFilter.type,
        value: {
          start: dateFilter.value.start.getTime(),
          end: dateFilter.value.end.getTime(),
        },
      });
    }
  }
};
