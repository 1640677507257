import { unstable_createMuiStrictModeTheme, createMuiTheme as stableCreateMuiTheme } from '@material-ui/core';

// this is removing annoying warning from console  "findDOMNode is deprecated in StrictMode."
// when material ui v5 will release it should change
// see https://stackoverflow.com/questions/61220424/material-ui-drawer-finddomnode-is-deprecated-in-strictmode
const createMuiTheme =
  process.env.NODE_ENV === 'development' ? unstable_createMuiStrictModeTheme : stableCreateMuiTheme;

export const darkGrey = '#42464E';
export const grey = '#888F9B';
export const lightGrey = '#D5D3DA';
export const lightestGrey = '#EEECF1';
export const blue = '#4285FF';
export const green = '#25C196';
export const lightGreen = '#00d86c24';
export const mint = '#48CFAD';
export const orange = '#FFCE54';
export const red = '#F5496B';
export const darkRed = '#ED5565';

export const fontWeightLight = 400;
export const fontWeightRegular = 500;
export const fontWeightMedium = 600;
export const fontWeightBold = 700;

export const borderRadius = 6;

export const weekCarouselBarHeight = 80;
export const mobileQnappenBarHeight = 64;
export const mobileTopBarHeight = 56;

const defaultTheme = createMuiTheme();
export const theme = createMuiTheme({
  palette: {
    primary: {
      light: grey,
      main: darkGrey,
    },
    secondary: {
      main: blue,
    },
    background: {
      default: lightestGrey,
    },
    success: {
      main: green,
      light: mint,
    },
    warning: {
      main: orange,
    },
    error: {
      main: red,
      dark: darkRed,
    },
    text: {
      primary: darkGrey,
      secondary: grey,
    },
    divider: lightestGrey,
    grey: {
      A100: lightestGrey,
      A200: lightGrey,
      A400: grey,
      A700: darkGrey,
    },
  },
  shape: {
    borderRadius,
  },
  typography: {
    fontFamily: ['"Poppins"', 'sans-serif', '"Roboto"', '"Helvetica"', '"Arial"', 'sans-serif'].join(','),
    fontWeightLight,
    fontWeightRegular,
    fontWeightMedium,
    fontWeightBold,

    allVariants: {
      lineHeight: 1.25,
    },
    overline: {
      color: grey,
      fontSize: '0.625rem',
      fontWeight: fontWeightMedium,
    },
    h6: {
      fontSize: '1.125rem',
    },
  },

  overrides: {
    MuiDialogTitle: {
      root: {
        paddingLeft: 0,
        paddingRight: 0,
        fontSize: '1.125rem',
        fontWeight: fontWeightRegular,
        textAlign: 'center',
      },
    },
    MuiButton: {
      sizeSmall: {
        fontSize: '0.875rem',
        fontWeight: fontWeightRegular,
      },
      sizeLarge: {
        height: 48,
        fontSize: '1rem',
        fontWeight: fontWeightRegular,
        textTransform: 'unset',
      },
      contained: {
        borderRadius: 99999,
        backgroundColor: '#FFFFFF',
      },
      outlined: {
        borderRadius: 99999,
      },
    },
    MuiChip: {
      sizeSmall: {
        height: 18,
        width: 64,
        '& span': {
          padding: 0,
        },
        fontSize: '0.625rem',
      },
      root: {
        backgroundColor: lightGreen,
        color: darkGrey,
      },
      outlined: {
        borderColor: lightestGrey,
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: '#00000066',
      },
    },
    MuiContainer: {
      maxWidthLg: {
        maxWidth: '1256px !important',
        [defaultTheme.breakpoints.down('sm')]: {
          padding: 0,
        },
      },
    },
    MuiInputBase: {
      input: {
        fontWeight: fontWeightRegular,
        fontSize: '0.875rem',
        '&::placeholder': {
          fontWeight: fontWeightLight,
          color: blue,
          opacity: 1,
        },
      },
    },
    MuiInputAdornment: {
      filled: {
        marginTop: '0 !important',
      },
    },
    MuiOutlinedInput: {
      input: {
        paddingTop: 18.5,
        paddingBottom: 18.5,
      },
      inputMarginDense: {
        paddingTop: 14.5,
        paddingBottom: 14.5,
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: lightestGrey,
        borderRadius,
      },
      input: {
        paddingTop: 18.5,
        paddingBottom: 18.5,
        '&:hover': {
          '&::before': {
            borderColor: 'transparent',
          },
          '&::after': {
            borderColor: 'transparent',
          },
        },
      },
      inputMarginDense: {
        paddingTop: 14.5,
        paddingBottom: 14.5,
      },
      multiline: {
        paddingTop: 18.5,
        paddingBottom: 18.5,
        '&.MuiFilledInput-marginDense': {
          paddingTop: 14.5,
          paddingBottom: 14.5,
        },
      },
      underline: {
        '&:hover': {
          '&::before': {
            borderColor: 'transparent',
          },
          '&::after': {
            borderColor: 'transparent',
          },
        },
        '&::before': {
          borderColor: 'transparent',
        },
        '&::after': {
          borderColor: 'transparent',
        },
      },
    },
    MuiAvatar: {
      colorDefault: {
        backgroundColor: lightestGrey,
        color: grey,
      },
      root: {
        fontSize: '0.875rem',
      },
    },
    MuiTabs: {
      indicator: {
        display: 'none',
      },
    },
    MuiDrawer: {
      paper: {
        overflow: 'hidden',
      },
    },
  },
  props: {
    MuiDialogTitle: {
      disableTypography: true,
    },
    MuiFormControl: {
      variant: 'filled',
      size: 'small',
    },
    MuiTextField: {
      variant: 'filled',
      size: 'small',
    },
    MuiSelect: {
      margin: 'dense',
      variant: 'filled',
    },
    MuiPaper: {
      elevation: 0,
    },
    MuiAppBar: {
      elevation: 0,
    },
    MuiButton: {
      disableElevation: true,
      focusRipple: false,
    },
    MuiIconButton: {
      focusRipple: false,
    },
    MuiTab: {
      focusRipple: false,
    },
  },
});
