import { eachDayOfInterval, format } from 'date-fns';

import { endOfWeek, startOfWeek } from 'config/dateFns';
import { Weekday } from 'generated/graphql';

export function getWeekday(date: Date | number): Weekday {
  return format(date, 'EEEE').toUpperCase() as Weekday;
}

export function fromWeekday(weekday: Weekday, fromDate: Date | number): Date {
  const allWeekDays = eachDayOfInterval({
    start: startOfWeek(fromDate),
    end: endOfWeek(fromDate),
  });
  const foundWeekday = allWeekDays.find((w) => getWeekday(w) === weekday);
  if (!foundWeekday) throw new Error('Day not found');
  return foundWeekday;
}
