import { CustomFrequency } from 'components/FrequencyPicker';
import { defaultDateFormat } from 'config/dateFns';
import { RecurrenceAttributes, TaskRecurrencePeriod } from 'generated/graphql';

import { fromWeekday } from './weekday';

interface getRecurrencesArgs {
  frequency: TaskRecurrencePeriod | 'CUSTOM';
  fromDate: Date;
  customFrequency?: CustomFrequency;
}

export function getRecurrences({ frequency, customFrequency, fromDate }: getRecurrencesArgs): RecurrenceAttributes[] {
  switch (frequency) {
    case TaskRecurrencePeriod.Once: {
      customFrequency;
      return [
        {
          firstOccurrenceAt: defaultDateFormat(fromDate),
          frequency: {
            period: frequency,
            value: 0,
          },
        },
      ];
    }
    case TaskRecurrencePeriod.Day:
    case TaskRecurrencePeriod.Week:
    case TaskRecurrencePeriod.Month:
    case TaskRecurrencePeriod.Year: {
      customFrequency;
      return [
        {
          firstOccurrenceAt: defaultDateFormat(fromDate),
          frequency: {
            period: frequency,
            value: 1,
          },
        },
      ];
    }
    case 'CUSTOM': {
      if (!customFrequency) throw new Error('Custom frequency is undefined');
      const period = customFrequency.repeat;
      const value = customFrequency.every;

      switch (period) {
        case TaskRecurrencePeriod.Once: {
          throw new Error('Custom frequency can not repeat once');
        }
        case TaskRecurrencePeriod.Week: {
          const weekdays = customFrequency.weekdays;
          if (!weekdays || weekdays.length === 0) throw new Error('Weekdays was not passed with period of week');
          return weekdays.map(weekday => {
            return {
              firstOccurrenceAt: defaultDateFormat(fromWeekday(weekday, fromDate)),
              frequency: {
                period,
                value,
              },
            };
          });
        }
        default: {
          return [
            {
              firstOccurrenceAt: defaultDateFormat(fromDate),
              frequency: {
                period,
                value,
              },
            },
          ];
        }
      }
    }
  }
}
