import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'state/store';

interface LocalitiesFiltersState {
  search?: string;
}

const initialState: LocalitiesFiltersState = {};

// slice
const localitiesFiltersSlice = createSlice({
  name: 'localitiesFilters',
  initialState,
  reducers: {
    setSearch: (state, { payload }: PayloadAction<string | undefined>) => {
      return { ...state, search: payload };
    },
  },
});

// reducer
export default localitiesFiltersSlice.reducer;

// actions
export const { setSearch } = localitiesFiltersSlice.actions;

// selectors
export const searchSelector = (
  state: RootState
): LocalitiesFiltersState['search'] => state.localitiesFilters.search;
