import { GridPositionType, LocalityGrid } from 'generated/graphql';

import { getGridRowsAndCols } from './getGridRowsAndCols';

export function getLocalityGridPositions(
  arrangement: LocalityGrid
): { id: GridPositionType; col: number; row: number }[] {
  const { rows, cols } = getGridRowsAndCols(arrangement);

  const values = [];
  for (let row = 1; row <= rows; row++) {
    for (let col = 1; col <= cols; col++) {
      values.push({ id: `_${col}X${row}` as GridPositionType, col, row });
    }
  }

  return values;
}
