import React, { forwardRef, ForwardRefRenderFunction } from 'react';

import { Grid, Box, CircularProgress } from '@material-ui/core';

const LoadingIndicator: ForwardRefRenderFunction<HTMLDivElement> = (
  props,
  ref
) => (
  <Grid ref={ref} justify="center" container>
    <Grid item>
      <Box m={2}>
        <CircularProgress />
      </Box>
    </Grid>
  </Grid>
);

export default forwardRef(LoadingIndicator);
