import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { getAccessToken, refreshAccessToken } from './functions/auth';

async function onRequest(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
  const accessToken = await getAccessToken();

  config.headers = {
    Authorization: `Bearer ${accessToken}`,
    'content-type': 'application/json',
    tenant_id: localStorage.getItem('tenantId'),
  };

  return config;
}

function httpClient(): AxiosInstance {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_URL,
  };

  const instance = axios.create(defaultOptions);

  instance.interceptors.request.use(onRequest);
  instance.interceptors.response.use(
    response => {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;
      if (error.response.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;
        const access_token = await refreshAccessToken();
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
        return instance(originalRequest);
      }
      return Promise.reject(error);
    },
  );

  return instance;
}

export default httpClient();
