import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { toast } from 'components/Toast';

export const useErrorHandling = (): void => {
  const { t } = useTranslation();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const error = params.get('error');
    if (error) {
      // TODO: Edit error messages translations so thehy contain more informations about error
      toast.error(t([`errors.${error}`, error]));
    }
  }, []);
};
