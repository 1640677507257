import React from 'react';

export function multiline(lines: string): JSX.Element[] {
  return lines.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ));
}
