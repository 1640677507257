import Axios from 'axios';

import { getClient } from 'config/apollo';
import { RefreshTokenDocument, RefreshTokenMutationResult } from 'generated/graphql';
import { print } from 'graphql/language/printer';

let isRefreshing = false;
let refreshPromise: Promise<void>;

const clientId = process.env.REACT_APP_AZURE_CLIENT_ID as string;
const redirectUri = process.env.REACT_APP_AZURE_LOGIN_REDIRECT_URI as string;

class RefreshTokenError extends Error {
  constructor(message: string | undefined, public shouldLogout: boolean) {
    super(message);
  }
}

export const refreshAccessToken = async (): Promise<void> => {
  if (isRefreshing) {
    return await refreshPromise;
  }
  isRefreshing = true;

  try {
    refreshPromise = doRefreshAccessToken();
    return await refreshPromise;
  } catch (error) {
    if (error instanceof RefreshTokenError) {
      if (localStorage.getItem('refreshToken')) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        const client = await getClient();
        await client.clearStore();
        localStorage.removeItem('precached-at');
        // If the refresh token's 24-hour lifetime has also expired, we will redirect user to MS login url to silently request a new authorization code
        // by leveraging the existing active session with Azure AD (if any), which will then be exchanged for a fresh set of tokens (access and refresh tokens).
        // Users do not have to enter their credentials, and usually don't even see any user experience, just a reload of your application.
        // The silent token requests to Azure AD might fail for reasons like a password change or updated conditional access policies.
        // More often, failures are due to the refresh token's 24-hour lifetime expiring and the browser blocking third party cookies.
        // More info in 'Refresh the access token' section here https://learn.microsoft.com/en-us/azure/active-directory/develop/v2-oauth2-auth-code-flow
        window.location.href = `https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?client_id=${encodeURIComponent(
          clientId,
        )}&response_type=code+id_token&redirect_uri=${encodeURIComponent(
          redirectUri,
        )}&response_mode=form_post&scope=openid+profile+email+Group.Read.All&state=12345&nonce=678910`;
      }
    } else {
      throw error;
    }
  } finally {
    isRefreshing = false;
  }
};
const doRefreshAccessToken = async () => {
  const refresh_token = localStorage.getItem('refreshToken');
  if (!refresh_token) {
    throw new RefreshTokenError('Refresh token does not exists', false);
  }

  const resp = await Axios.post<RefreshTokenMutationResult>(process.env.REACT_APP_APOLLO_URI as string, {
    query: print(RefreshTokenDocument),
    variables: { refresh_token },
  });

  const data = resp.data.data?.refreshToken;

  if (!data?.success) {
    const message = data?.errors[0].message;
    throw new RefreshTokenError(message, true);
  }

  const newAccessToken = data?.accessToken;
  const newRefreshToken = data?.refreshToken;

  if (!newAccessToken || !newRefreshToken) {
    throw new RefreshTokenError('Did not received tokens', true);
  }

  localStorage.setItem('accessToken', newAccessToken);
  localStorage.setItem('refreshToken', newRefreshToken);
};
