import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'state/store';

export enum ToastType {
  Success = 'success',
  Error = 'error',
  Info = 'info',
  Warning = 'warning',
}

export interface ToastOptions {
  timeout?: number;
}

const defaultToastOptions: ToastOptions = {};
export interface Toast {
  id: string;
  type: ToastType;
  message: string;
  open: boolean;
  options?: ToastOptions;
}

interface ToastsState {
  toasts: Toast[];
}

const initialState: ToastsState = {
  toasts: [],
};

const similarToasts = (
  left: Pick<Toast, 'message' | 'type'>,
  right: Pick<Toast, 'message' | 'type'>
) => {
  return left.message === right.message && left.type === right.type;
};

// slice
const toastsSlice = createSlice({
  name: 'toasts',
  initialState,
  reducers: {
    addToast: (
      state,
      { payload }: PayloadAction<Omit<Toast, 'id' | 'open'>>
    ) => {
      if (state.toasts.some((toast) => similarToasts(toast, payload))) {
        return state;
      }
      const randomId = Math.floor(Math.random() * 2 ** 32).toString(16);
      return {
        ...state,
        toasts: [
          ...state.toasts,
          {
            ...payload,
            id: randomId,
            open: true,
            options: Object.assign({}, defaultToastOptions, payload.options),
          },
        ],
      };
    },
    updateToast: (
      state,
      { payload }: PayloadAction<Partial<Toast> & { id: string }>
    ) => {
      return {
        ...state,
        toasts: state.toasts.map((toast) =>
          toast.id !== payload.id ? toast : Object.assign({}, toast, payload)
        ),
      };
    },
    removeToast: (state, { payload }: PayloadAction<string>) => {
      return {
        ...state,
        toasts: state.toasts.filter((toast) => toast.id !== payload),
      };
    },
  },
});

// reducer
export default toastsSlice.reducer;

// actions
export const { addToast, removeToast, updateToast } = toastsSlice.actions;

//selectors
export const toastsSelector = (state: RootState): ToastsState['toasts'] =>
  state.toasts.toasts;
